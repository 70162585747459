<template>
  <div class="filter-group">
    <div v-for="(condition, groupId) of conditions" :key="groupId" class="filter-group--container">
      <div v-if="groupId >= 1" class="filters--type-selector">
        <filter-conditional-connector
          :key="groupId"
          select-class="filters--type-selector__container"
          :value="condition.type"
          @change="filtersMixin.changeSegmentType()"
        />
      </div>

      <template v-for="(predicate, predicateId) of condition.predicates" :key="predicateId">
        <div style="display: inline-block">
          <div v-if="predicateId > 0" class="filter-group--connector">
            <filter-conditional-connector
              select-class="filter-group--connector__content"
              :group-id="groupId"
              :value="condition.type"
              @change="filtersMixin.changeConditionType()"
            />
          </div>
          <n-filters-group-item
            :name="filterStore.getFilterByAttr(predicate.attr).name"
            :attr="predicate.attr"
            :comparison="predicate.comparison"
            :value="predicate.value"
            @update="filtersMixin.updatePredicate($event, groupId, predicateId)"
            @delete="filterStore.removeGroupPredicate({ groupId, predicateId })"
          />
        </div>
      </template>

      <div
        v-if="filterStore.addFilterEnabled && condition.predicates.length > 0"
        class="filter-group--add"
      >
        <a-dropdown :trigger="['click']">
          <n-tooltip :text="$t('Add a Filter')" placement="top">
            <a class="ant-dropdown-link filter-group--add__content" href="#">
              <i class="gx-m-0 fal fa-plus" />
            </a>
          </n-tooltip>
          <template #overlay>
            <div class="filters--adder-dd">
              <filter-list-menu @click="filtersMixin.addConditionPredicate(groupId, $event)" />
            </div>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import NFiltersGroupItem from '@/components/filters/filter-group-item.vue'
import useFilterStore from '@/store/modules/filter'
import useFiltersMixin from '@/mixins/filters-mixin'
import FilterListMenu from '@/components/filters/filter-list-menu.vue'
import FilterConditionalConnector from '@/components/filters/filter-conditional-connector.vue'
import NTooltip from '@/components/ui/n-tooltips/n-tooltip.vue'

const filtersMixin = useFiltersMixin()
const filterStore = useFilterStore()

defineProps({
  conditions: {
    type: Array,
    required: true,
  },
  segmentType: {
    type: String,
    required: true,
  },
})
</script>
<style>
.filter-group--connector__content .ant-select-selection-item {
  color: #737376;
  font-weight: 600 !important;
  padding-inline-end: 0px !important;
}
.filter-group--connector__content .ant-select-selector {
  background-color: #f6f6f6 !important;
}
</style>
