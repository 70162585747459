<template>
  <ag-grid-vue
    data-cy="activities-grid"
    style="width: 100%; height: 80vh"
    class="ag-theme-balham activities-table"
    :column-defs="columnDefs"
    :row-data="items"
    :default-col-def="defaultColDef"
    :side-bar="agTimelineDefs.sidebar"
    :status-bar="agTimelineDefs.statusPanels"
    :tools-panel="agTimelineDefs.toolPanels"
    :row-drag="agTimelineDefs.rowDrag"
    :animate-rows="true"
    :master-detail="true"
    :suppress-no-rows-overlay="true"
    :row-group-panel-show="'always'"
    :enable-cell-text-selection="false"
    :enable-browser-tooltips="true"
    :suppress-copy-rows-to-clipboard="true"
    :suppress-row-click-selection="true"
    row-selection="single"
    :pivot-panel-show="'always'"
    :enable-range-selection="true"
    :enable-charts="true"
    :modules="agGridMixin.agModules"
    :get-row-id="getRowNodeId"
    @row-clicked="handleRowDblClick"
    @grid-ready="(params) => onGridReady(params, 'activities')"
  />
</template>

<script setup>
import { AgGridVue } from 'ag-grid-vue3'
import useActivityMixin from '@/mixins/activity-mixin'
import useAgGridMixin from '@/mixins/agGridMixin'
import { getNIcon } from '@/helpers/component-loader.js'
import useTimelineStore from '@/store/modules/timeline'
import useDocumentStore from '@/store/modules/document'
import defaultColDef from '@/mixins/globalDefaultColDef'
import { computed, defineProps, ref } from 'vue'
import agTimelineDefs from '@/config/ag-grid-defs/ag-activities.js'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import useActivityStore from '@/store/modules/activity'

const props = defineProps({
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  items: {
    type: Array,
    default: () => [],
  },
})

const gridApi = ref()
const activityMixin = useActivityMixin()
const documentNLPMixin = useDocumentNLPMixin()
const agGridMixin = useAgGridMixin()
const documentStore = useDocumentStore()
const timelineStore = useTimelineStore()
const activityStore = useActivityStore()

const columnDefs = computed(() => {
  const columns = agTimelineDefs.columnDefs

  for (let column of columns) {
    if (column.field === 'ActivityIcon') {
      column.cellClass = (params) => {
        if (params.data) {
          return [
            params.data
              ? `has-background-${activityMixin.getActivityColor(params.data.ActivityCategory)}`
              : 'has-background-white',
          ]
        }
      }
      column.cellRenderer = (params) => {
        if (params.data) {
          const icon = getNIcon({
            type: 'fal',
            icon: activityMixin.getActivityIcon(params.data.ActivityCategory),
          })

          return `<div class="activity-icon-wrapper">${icon ? icon.outerHTML : ''}</div>`
        }
      }
    }
    if (column.field === 'ActivityStatusIcon') {
      column.cellRenderer = (params) => {
        if (params.data) {
          const icon = getNIcon({
            type: params.data.illogical ? 'fas' : 'fal',
            icon: params.data.illogical
              ? activityMixin.getStatusIcon('illogical')
              : activityMixin.getStatusIcon(params.data.ActivityStatus),
          })

          return `<div><span class="activity-status-wrapper icon ${
            params.data.illogical
              ? `ilg-severity-${params.data.illogicalSeverity}`
              : `has-text-${
                  params.data ? activityMixin.getStatusColor(params.data.ActivityStatus) : 'null'
                }`
          }">${icon?.outerHTML}</span></div>`
        }
      }
    }
    if (column.field === '_documents') {
      column.valueGetter = (params) => {
        if (params.data) {
          return documentStore.getTotalDocumentsByActivityId(params.data.ActivityUniqueID)
        }
      }
    }
  }

  return columns
})

// TODO: not sure if this is needed.
// watch(
//   isLoading,
//   () => {
//     if (isLoading.value) {
//       gridApi.value.showLoadingOverlay()
//     } else {
//       gridApi.value.hideOverlay()
//     }
//   },
//   sortModeOptions.value,
//   (options) => {
//     if (!_.isUndefined(_.first(options))) {
//       setGridSortOptions(options)
//     }
//   },
//   selectedActivity.value,
//   (item) => {
//     if (item && item.ActivityUniqueID) {
//       let row = gridApi.value.getRowNode(item.ActivityUniqueID)

//       if (row) {
//         row.setSelected(true)
//         gridApi.value.ensureIndexVisible(row.rowIndex)
//       }
//     }
//   }
// )

// TODO: replace with global events
// onMounted(() => {
//   bus.$on('timeline-scroll-to-activity', (activity) => {
//     scrollToItem(activity.ActivityUniqueID)
//   })
// })

// This was commented out when we updated ag-grid
// handleSortChange() {
//   const sortOptions = gridApi.value.getSortModel()

//   // Loop can be removed and SET_SORT_OPTIONS can use the sort model directly if all cols are to be sorted
//   for (let sort of sortOptions) {
//     if (sort.colId === 'ActivityDate' && this.sortModeOptions && this.sortModeOptions[0].sort !== sort.sort) {
//       this.sortModeOptions = [{ sort: sort.sort, colId: 'ActivityDate' }]
//     }
//   }
// },
// const setGridSortOptions = (options) => {
//   if (gridApi.value) {
//     gridApi.value.setSortModel(options)
//   }
// }

const handleRowDblClick = (item) => {
  scrollToItem(item.data)
  documentNLPMixin.selectAgRows(item)
}

// highlight row with selected ActivityUniqueID and scroll to it if not visible in the grid viewport
const scrollToItem = async (item) => {
  if (item && item.ActivityUniqueID) {
    let row = gridApi.value.getRowNode(item.ActivityUniqueID)

    activityStore.loadDetails({
      ActivityID: item.ActivityOID,
      ActivityType: item.ActivityType,
    })

    if (row) {
      row.setSelected(true)
      gridApi.value.ensureIndexVisible(row.rowIndex)

      timelineStore.selectedActivity = item
      timelineStore.visTimeline.setSelection(`activity::${item.ActivityUniqueID}`)
      documentNLPMixin.selectDocumentViewer({ ActivityUniqueID: item.ActivityUniqueID }, true)
    }
  }
}
// set a unique row node id
const getRowNodeId = (row) => {
  return row.data.ActivityUniqueID // return the property you want set as the id.
}

const onGridReady = (params, gridName) => {
  gridApi.value = agGridMixin.onGridReady(params, gridName).api

  if (props.loading) {
    gridApi.value.showLoadingOverlay()
  }

  if (timelineStore.selectedActivity) {
    scrollToItem(timelineStore.selectedActivity)
  }

  gridApi.value.sizeColumnsToFit()

  //this.handleSortChange()
}
</script>

<style lang="less">
.activity-icon-wrapper {
  text-align: center;
  width: 100%;
  display: inline-block;
  & .fa-icon {
    margin: 0;
  }
}
.activity-status-wrapper {
  border-radius: 50%;
  width: 20px;
  text-align: center;
  height: 20px;
  display: inline-block;
  background: #e2e6fb;
  top: 2px;
  position: relative;
  & .fa-icon {
    margin: 0;
    left: 0;
    position: relative;
  }
}
.activities-table {
  // Override the background color of the ag-selected-row
  --ag-selected-row-background-color: #fff785 !important;
}
</style>
