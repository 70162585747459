<template>
  <a-menu class="filterListMenu">
    <a-menu-item-group
      v-for="filterHeading of filterHeadings"
      :key="filterHeading"
      :title="filterHeading"
    >
      <a-menu-item
        v-for="filter of filterMenu[filterHeading]"
        :key="filter.attr"
        :content="filter.desc"
        style="width: 100%"
        @click="emitClick(filter.attr)"
      >
        <div class="">
          <i class="n-mr-2 fal fa-plus" />
          {{ filter.name }}
        </div>
      </a-menu-item>
    </a-menu-item-group>
  </a-menu>
</template>
<script setup>
import { computed } from 'vue'
import useFilterStore from '@/store/modules/filter'

const emit = defineEmits(['click'])
const filterStore = useFilterStore()

// Builds the filter menu in a format suitable for the antd menu
const filterMenu = computed(() => {
  const menu = filterStore.filtersList
    // map metaType to the display label
    .map((filter) => ({
      ...filter,
      label: filterStore.filterGroups[filter.metaType]?.label || 'Unknown',
    }))
    // Group the filters by metaKey and map the label for the metaKey
    .reduce((r, a) => {
      r[a.label] = r[a.label] || []
      r[a.label].push(a)
      return r
    }, {})
  let sortedMenu = {}
  Object.keys(menu).forEach((key) => {
    sortedMenu[key] = menu[key].sort((a, b) => a.attr.localeCompare(b.attr))
  })
  return sortedMenu
})

const filterHeadings = computed(() => {
  return Object.keys(filterMenu.value)
})

const emitClick = (attr) => {
  emit('click', attr)
}
</script>
<style lang="less">
@import '../../styles/variables';

.filterListMenu {
  .ant-menu-item-group-title {
    font-weight: bold;
  }
  .ant-menu-item {
    line-height: 28px;
    height: 28px;
    margin-bottom: 6px;
  }
  .ant-menu-item:hover {
    background: #eeeeee;
  }
}
</style>
