// Our text might contain special chars that will trip up the RegEx
// This will escape them all
const rxSpecialChars = /[-[\]{}()*+?.,\\^$|#]/g

/**
 * @description Escape regex special characters in a string.
 * @param {string} str - The string to escape.
 * @returns {string} The escaped string.
 */
const escapeRxSpecialChars = (str) => str.replace(rxSpecialChars, '\\$&')
const wordEnding = '[\\n\\s\\.\\?\\)!:<]'

/**
 * @description Replace the nth occurrence of a string (`f`) with another string (`r`) in a string (`s`).
 * @example
 *   replaceNth('I have two dogs', 'dogs', 'cats', 1)
 *   // 'I have two cats'
 *   replaceNth('I have two dogs', 'dogs', 'cats', 2)
 *   // 'I have two dogs' (no change as there is no second occurrence)
 * @param {string} s - The string to search.
 * @param {string} f - TThe string to find (case insensitive). Regex special characters are escaped.
 * @param {string} r - The string to replace with.
 * @param {number} n - The nth occurrence to replace.
 * @returns {string} The modified string.
 */
const replaceNth = (s, f, r, n) => {
  // Escape special characters in the string we are searching for
  const needle = escapeRxSpecialChars(f)

  // We intentionally trim the string and then add a [space] to the end, to help the regex with ${wordEnding} at the end of text
  const haystack = `${s.trim()} `

  // This pattern will find the nth occurrence of our needle, where the needle is followed by a ${wordEnding} character
  // we force the needle to be followed by a ${wordEnding} character to stop the replace from breaking up words containing a ${needle} substring
  const pattern = `^(?:.*?${needle}${wordEnding}){${n}}`
  // Create a regex using flags: g: global, i: case-insensitive, s: single (. matches newlines)
  const rx = RegExp(pattern, 'gis')

  // replace the needle, retaining the wordEnding and trim off the space added to the haystack earlier on
  return haystack
    .replace(rx, (x) => x.replace(RegExp(`(${needle})(${wordEnding})$`, 'i'), `${r}$2`))
    .trim()
}

/**
 * @description Wrap the nth occurrence of a string with another string.
 * @example
 *   wrapNth('I have two dogs', 'dogs', '#MATCH#', 1)
 *   // 'I have two #MATCH#dogs#MATCH#'
 *   wrapNth('I have two dogs', 'dogs', '#MATCH#', 2)
 *   // 'I have two dogs' (no change as there is no second occurrence)
 * @param {any} s- The string to search.
 * @param {any} f - The string to find (case insensitive). Regex special characters are escaped.
 * @param {any} r - The string to wrap with.
 * @param {any} n - The nth occurrence to wrap.
 * @returns {string} The modified string.
 */
const wrapNth = (s, f, r, n) => replaceNth(s, f, `${r}$1${r}`, n)

export { escapeRxSpecialChars, replaceNth, wrapNth }
