import { createI18n } from 'vue-i18n'
import messages from '../../locales/en-gb'

export const i18n = createI18n({
  legacy: false,
  locale: 'en-gb',
  fallbackLocale: 'en',
  missingWarn: false,
  fallbackWarn: false,
  warnHtmlMessage: false,
  globalInjection: true,
  allowComposition: true,
  messages,
})
