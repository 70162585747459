import { defineStore } from 'pinia'
import { api } from '@/services/http'
import { activityService } from '@/services/endpoints/activity'
import useValidationStore from '@/store/modules/validation'
import patientService from '@/services/endpoints/patient'
import { reactive, ref } from 'vue'
import config from '@/config'
import useFilterStore from '@/store/modules/filter'
import _ from 'lodash'

export default defineStore('timeline', () => {
  const filterStore = useFilterStore()

  // PTL
  const initialPTLUniqueID = ref(null) // Needed for validation form (only supports 1 ID)
  const currentIDs = reactive({
    PTLUniqueID: null,
    patientId: null,
    pathwayId: null,
    CohortID: null,
    CohortCode: null,
  })
  const currentPatient = ref([{}])

  const timeline = ref([])
  const timelineLoading = ref(false)
  const rttData = ref([])
  const validationHistory = ref([])
  const selectedDocument = reactive({})
  const selectedActivity = reactive({})
  const sortModeOptions = ref([{ sort: 'desc', colId: 'ActivityDate' }])

  // Filters
  const activitySearchInput = ref()
  const dateRangeFilter = ref([])
  const filters = reactive({
    patientID: null,
    pathwayID: null,
  })
  const error = ref(false)
  const loadedOnce = ref(false)
  const eventViewerTab = ref('activities') // Active tab in Documents panel
  const visTimeline = ref(null) // Currently active visTimeline class
  const censusDate = ref(null)
  const timelineHeaderFilterChanged = ref(false)
  const timelineFilterChanged = ref(false)
  const dateSliderChanged = ref(false)

  const preloadTimeline = async (PTLUniqueIDs) => {
    const validationStore = useValidationStore()

    timelineLoading.value = true

    const TimelineQuery = api.post('/timeline/query', {
      PTLUniqueID: PTLUniqueIDs,
    })

    const RTTQuery = activityService.getRTTData({
      PTLUniqueID: PTLUniqueIDs,
    })

    // Loads all validation history or ag-grid
    const validationQuery = validationStore.loadAllValidationHistory(PTLUniqueIDs)

    await Promise.all([TimelineQuery, RTTQuery, validationQuery]).then((resp) => {
      timelineLoading.value = false

      timeline.value = resp[0]

      // Update the filter options
      for (let filterField of config.ACTIVITY_FILTER_FIELDS) {
        const filterOptions = _.map(_.uniqBy(timeline.value, filterField), (item) => {
          return {
            attr: item[filterField],
            name: item[filterField],
          }
        }).filter((elm) => {
          return !!elm.name
        })
        filterStore.setFilterOptions({ attr: filterField, options: filterOptions })
      }

      rttData.value = resp[1]
    })
  }

  const fetchPatient = async (PTLUniqueID) => {
    const patient = await patientService.getPID(PTLUniqueID[0])
    currentPatient.value = patient
  }

  const updateDefaultValues = (timelineSortBy) => {
    if (!timelineSortBy) return
    sortModeOptions.value[0].sort = timelineSortBy
  }
  // TODO: Refactor
  // const resetModel = () => {
  //   this.$patch(defaultState())
  // }

  return {
    initialPTLUniqueID,
    currentIDs,
    timeline,
    currentPatient,
    timelineLoading,
    rttData,
    validationHistory,
    selectedDocument,
    selectedActivity,
    sortModeOptions,
    activitySearchInput,
    dateRangeFilter,
    filters,
    error,
    loadedOnce,
    eventViewerTab,
    visTimeline,
    preloadTimeline,
    fetchPatient,
    updateDefaultValues,
    timelineHeaderFilterChanged,
    timelineFilterChanged,
    dateSliderChanged,
    censusDate,
  }
})
