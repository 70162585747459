<template>
  <div>
    <a-tooltip title="Tooltip Info">
      <div id="wrapper">
        <a-switch
          class="n-mb-0 n-mr-1 tt-switch"
          data-cy="tt-switch"
          v-model:checked="uiStore.state.tooltipsEnabled"
          default-checked
          @click="uiStore.setDefaultField('tooltipsEnabled', checked)"
        />
        <n-icon
          class="n-m-0"
          :class="uiStore.state.tooltipsEnabled ? 'info' : 'info off'"
          :icon="['fas', 'info-circle']"
          size="lg"
        />
      </div>
    </a-tooltip>
  </div>
</template>
<script setup>
import NIcon from '@/components/ui/n-icon/n-icon.vue'
import useUIStore from '@/store/modules/ui'

const uiStore = useUIStore()
</script>
<style lang="less">
.tt-switch {
  z-index: 98;
  pointer-events: auto;
}
#wrapper {
  position: relative;
}
.n-m-0.info {
  position: absolute;
  top: 3px;
  left: 26.5px;
  transition: left 0.2s;
  transition-timing-function: cubic-bezier(0.39, -0.01, 0.53, 0.78);
  z-index: 99;
  pointer-events: none;
  &.off {
    left: 5px;
  }
}
</style>
