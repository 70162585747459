import { defineAbility } from '@casl/ability'
import useConfigStore from '@/store/modules/config.js'
let ability = null

const initAbilities = () => {
  const config = useConfigStore()

  ability = defineAbility((can) => {
    for (const permission of config.permissions) {
      can(permission.actions, permission.subject)
    }
  })

  return ability
}

export default () => {
  if (!ability) {
    ability = initAbilities()
  }

  return ability
}
