import { defineStore } from 'pinia'
import { configService } from '@/services/endpoints/config'
import ls from '@/helpers/localStorage'
import config from '@/config'
import { merge } from 'lodash'
import { i18n } from '@/plugins/i18n.js'
import localTranslations from '@/translations.json'

export default defineStore('i18n', {
  state: () => {
    return {
      translations: {
        en: ls.get('translations-en'),
      },
    }
  },
  actions: {
    setTranslations(payload) {
      this.$state.translations[payload.code] = payload.translations
      ls.set(
        `translations-${payload.code}`,
        merge(localTranslations, payload.translations),
        config.LS_TTL_TRANSLATIONS,
      )
    },
    async fetchLocale(code) {
      // If debug flag nc-translations is detected disable translations
      if (ls.get('nc-translations')) {
        return Promise.resolve({})
      }

      const cached = ls.get(`translations-${code}`)

      if (cached && !config.IS_DEVELOPMENT) {
        i18n.global.setLocaleMessage(code, cached)
        return Promise.resolve(cached)
      }

      const locale = await configService.getLocale(code)

      try {
        this.setTranslations({
          code,
          translations: locale,
        })
        i18n.global.setLocaleMessage(code, merge(localTranslations, locale))
        return locale
      } catch (error) {
        i18n.global.setLocaleMessage(code, localTranslations)
      }
    },
  },
})
