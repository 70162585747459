import agTimelineDefs from '@/config/ag-grid-defs/ag-activities.js'
import agClassificationListDefs from '@/config/ag-grid-defs/ag-classifications.js'
import agDocumentsListDefs from '@/config/ag-grid-defs/ag-documents.js'
import visTimelineOptions from '@/config/timeline/vis-timeline-options.js'
import { merge } from 'lodash'

let config = {
  RELEASE_NAME: import.meta.env.RELEASE_NAME,
  PACKAGE_VERSION: import.meta.env.PACKAGE_VERSION,
  PACKAGE_BUILD_NUMBER: import.meta.env.PACKAGE_BUILD_NUMBER,
  MAINTENANCE: import.meta.env.VUE_APP_MAINTENANCE === 'true' || false,
  apiUrl: import.meta.env.VUE_APP_API_HOST,
  BASE_URL: import.meta.env.VUE_APP_BASE_URL || '/',
  ACTIVITY_FILTER_FIELDS: [
    'ActivityType',
    'ActivityCategory',
    'Specialty',
    'TreatmentFunction',
    'Site',
    'Consultant',
    'ActivityStatus',
  ],
  CLASSIFICATION_FILTER_FIELDS: [
    'ClassifierLabel',
    'DocumentCategory',
    'ClassificationStatusLabel',
    'LetterType',
    'LinkedTo',
    'RovaVersion',
    'ConfidenceLevel',
  ],
  sentry: {
    enable: true,
    dsn: import.meta.env.VUE_APP_SENTRY_DSN || null,
    env: import.meta.env.VUE_APP_SENTRY_ENV || null,
    tracesSampleRate: import.meta.env.VUE_APP_SENTRY_TRACE_SAMPLE || 0.25,
    tracingOrigin: import.meta.env.SENTRY_TRACE_ORIGIN || null,
  },
  browser: {
    domain: import.meta.env.VUE_APP_DOMAIN,
  },
  services: {
    luna: import.meta.env.VUE_APP_LUNA_ENDPOINT || 'localhost:3001',
    forms: import.meta.env.VUE_APP_FORMS_ENDPOINT || 'localhost:5100',
    probeUrl: import.meta.env.VUE_APP_ROVA_API_HOST || 'http://localhost:5000',
    formsFrontend: import.meta.env.VUE_APP_FORMS_FRONTEND || 'http://localhost:8081',
    AGGridFrontend: import.meta.env.VUE_APP_AGGRID_FRONTEND || 'http://localhost:8999',
    validationURL: {
      endpoint:
        import.meta.env.VUE_APP_PATHFINDER_VALIDATE_URL ||
        'https://form.uat.mbiluna.com/forms/rtt-validation',
      params: {
        HistoryLoadLatest: true,
      },
    },
    ValidationHistoryURL: {
      endpoint: import.meta.env.VUE_APP_PATHFINDER_VALIDATE_HISTORY_URL,
      params: {
        PTLUniqueID: true,
      },
    },
  },
  unlinkedFilesDefaultTimelineActivityDate:
    import.meta.env.UNLINKED_FILES_DEFAULT_TIMLINE_ACTIVITY_DATE || 'earliest',
  LS_TTL_CONFIG: import.meta.env.VUE_APP_LS_TTL_CONFIG || 60,
  LS_TTL_TRANSLATIONS: import.meta.env.VUE_APP_LS_TTL_TRANSLATIONS || 60,
  IS_DEVELOPMENT: import.meta.env.NODE_ENV === 'development',
  LOCALE: {
    SQL_DATE_FORMAT: 'YYYY-MM-DD',
    SQL_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    DEFAULT_DATE_FORMAT: 'DD/MM/YYYY',
    DEFAULT_DATE_TIME_FORMAT: 'DD/MM/YYYY hh:mm:ss',
  },
  agDefs: {
    classificationList: agClassificationListDefs,
    documentList: agDocumentsListDefs,
  },
  timeline: {
    // eslint-disable-next-line global-require
    agTimelineDef: agTimelineDefs,
    visTimelineOptions: visTimelineOptions,
    RTTClockDisplayUnits: 'weeks',
  },
  aad: {
    auth: {
      clientId: null,
      authority: null,
      redirectUri: window.location.origin,
    },
    cache: {
      // cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    api: {
      scopes: null,
    },
  },
}

export const mergeConfig = (hostSpecificConfig) => {
  if (hostSpecificConfig) {
    config = merge(config, hostSpecificConfig)
  }
}

export default config
