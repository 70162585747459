import { defineStore } from 'pinia'
import _ from 'lodash'
import { ref, reactive } from 'vue'

export default defineStore('filters', () => {
  const segment = reactive({
    type: 'and',
    conditions: [],
  })
  const filterValues = reactive({})
  /** Enable the ability to a singular filter * */
  const addFilterEnabled = ref(true)
  /** Enable appending new filter segments * */
  const addGroupEnabled = ref(false)
  const filterGroups = ref([])
  const filtersList = ref([])
  const filterTypeOptions = [
    {
      attr: 'in',
      name: 'Includes',
      type: ['array'],
      category: 'relative',
      field: 'array',
    },
    {
      attr: 'nin',
      name: 'Exclude',
      type: ['array'],
      category: 'relative',
      field: 'array',
      hideFrom: ['_custom.equity_status'],
    },
    {
      attr: 'more_than',
      name: 'more than',
      type: ['number', 'date'],
      affix: {
        date: 'days ago',
      },
      category: 'relative',
      field: 'input',
    },
    {
      attr: 'less_than',
      name: 'less than',
      type: ['number', 'date'],
      affix: {
        date: 'days ago',
      },
      category: 'relative',
      field: 'input',
    },
    {
      attr: 'exactly',
      name: 'exactly',
      type: ['number', 'date', 'string'],
      affix: {
        date: 'days ago',
      },
      category: 'relative',
      field: 'input',
    },
    {
      attr: 'before',
      name: 'before',
      type: ['date'],
      category: 'absolute',
      field: 'date',
    },
    {
      attr: 'after',
      name: 'after',
      type: ['date'],
      category: 'absolute',
      field: 'date',
    },
    // {
    //   attr: 'on',
    //   name: 'on',
    //   type: [ 'date', ],
    //   category: 'absolute',
    //   field: 'date'
    // }
  ]

  const getFilters = () => {
    if (segment.conditions && segment.conditions.length > 0) {
      return segment.conditions[0].predicates || []
    }
    return []
  }

  const getFilterByAttr = (attr) => {
    return _.find(filtersList.value, { attr: attr })
  }
  // Don't think this is used anywhere?
  // const setFilterField = ({ value, field }) => {
  //   Vue.set(this.filterValues, field, value)
  // }

  const addCondition = (payload) => {
    const conditions = _.clone(segment.conditions)

    conditions.push(payload)

    segment.conditions = conditions
  }

  const removeGroup = (payload) => {
    segment.conditions.splice(payload.groupId, 1)
  }
  const setSegmentType = (payload) => {
    segment.type = payload.type
  }

  const setConditionType = (payload) => {
    const container = segment.conditions[payload.segment]

    if (container) {
      container.type = payload.type
    }
  }

  const updateGroupPredicate = (payload) => {
    const group = _.clone(segment.conditions[payload.groupId])

    for (const field in payload.data) {
      group.predicates[payload.predicateId][field] = payload.data[field]
    }

    segment.conditions[payload.groupId] = group
  }

  const removeGroupPredicate = (payload) => {
    const group = segment.conditions[payload.groupId]
    if (group) {
      group.predicates.splice(payload.predicateId, 1)
    }

    if (segment.conditions[payload.groupId].predicates?.length <= 0) {
      removeGroup(payload)
    }
  }

  const addGroupPredicate = (payload) => {
    const container = segment.conditions[payload.segment]

    if (container) {
      container.predicates.push(payload.condition)
    }
  }

  const setFilterOptions = ({ attr, options }) => {
    // Skip if empty
    if (!filtersList.value.length) {
      return false
    }

    // Attempt to retrieve the desired attr
    const field = _.find(filtersList.value, { attr: attr })

    // If the attr is not defined, cancel
    if (!field) {
      return false
    }

    field.options = options
  }

  const overwriteFilterOptions = (payload) => {
    filtersList.value = payload
  }

  const overwriteFilterGroups = (payload) => {
    filterGroups.value = payload
  }

  return {
    segment,
    filterValues,
    addFilterEnabled,
    addGroupEnabled,
    filterGroups,
    filtersList,
    filterTypeOptions,
    getFilters,
    getFilterByAttr,
    // setFilterField,
    addCondition,
    removeGroup,
    setSegmentType,
    setConditionType,
    updateGroupPredicate,
    removeGroupPredicate,
    addGroupPredicate,
    setFilterOptions,
    overwriteFilterOptions,
    overwriteFilterGroups,
  }
})
