import log from '@/logger'
import useUIStore from '@/store/modules/ui'

export default (error) => {
  const uiStore = useUIStore()

  if (error.response) {
    log.error('API', error.response.status, error.response.config.url, error.response)

    if (error.response.status === 403 || error.response.status === 401) {
      uiStore.state.error = error
      console.log('🚀 ~ file: response-error.js ~ line 13 ~ error', error)
    }
  } else if (error.request) {
    uiStore.state.error = true
    log.error('The request was made but no response was received')
  } else {
    uiStore.state.error = true
    log.error(error)
  }

  if (error.message) {
    log.error(`Request to ${error.request.url} returned error message: ${error.message}`)
  }

  uiStore.loaderFinish()
  return Promise.reject(error)
}
