<template>
  <a-select
    default-value="and"
    :class="[selectClass]"
    :disabled="true"
    :value="value"
    @change="filtersMixin.changeConditionType(value, groupId)"
  >
    <a-select-option value="and"> And </a-select-option>
    <a-select-option value="or"> Or </a-select-option>
  </a-select>
</template>

<script setup>
import useFiltersMixin from '@/mixins/filters-mixin'
const filtersMixin = useFiltersMixin()

defineProps({
  selectClass: {
    type: String,
    required: false,
    default: '',
  },
  groupId: {
    type: [String, Number],
    required: false,
    default: null,
  },
  value: {
    type: String,
    required: true,
  },
})
</script>
