const set = (key, value, ttl) => {
  const ttlMs = ttl * 1000
  const data = {
    value,
    expires_at: new Date().getTime() + ttlMs,
  }

  localStorage.setItem(key.toString(), JSON.stringify(data))
}

const get = (key, defaultValue) => {
  try {
    const data = JSON.parse(localStorage.getItem(key.toString()))

    if (data !== null) {
      if (data.expires_at !== null && data.expires_at < new Date().getTime()) {
        localStorage.removeItem(key.toString())
      } else {
        return data.value
      }
    }
  } catch (e) {
    localStorage.removeItem(key.toString())
  }
  return defaultValue || null
}

const del = (key) => {
  localStorage.removeItem(key.toString())
}

export default {
  set,
  get,
  del,
}
