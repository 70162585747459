let getTargetParentNode = (range, targetClass) => {
  if (!range.commonAncestorContainer) {
    throw Error("Can't find ancestor container")
  }

  // To prevent endless loops
  let nestedOperations = 0,
    maxNestedOperations = 10

  // Track whether the desired node was found
  let isDocumentNode = false

  // Set the common ancestor as the starting point
  let currentNode = range.commonAncestorContainer ? range.commonAncestorContainer : range.parentNode

  while (!isDocumentNode && nestedOperations <= maxNestedOperations) {
    // Check if we have a match
    if (currentNode.className === targetClass) {
      isDocumentNode = true
    } else {
      // If the desired parent was not found, get the next parent node
      currentNode = currentNode.parentNode

      // Increment the nested operations counter to prevent endless loops
      nestedOperations++
    }
  }

  if (nestedOperations >= maxNestedOperations) {
    throw Error(`Max nested operations reached, ${targetClass} could not be found`)
  }

  return currentNode
}

const getSelectionOffsetRelativeTo = (parentElement, currentNode) => {
  let currentSelection,
    currentRange,
    offset = 0,
    prevSibling,
    nodeContent

  // Use the highlighted node as the default if none is defined
  if (!currentNode) {
    currentSelection = window.getSelection()
    currentRange = currentSelection.getRangeAt(0)
    // eslint-disable-next-line no-param-reassign
    currentNode = currentRange.startContainer
    offset += currentRange.startOffset
  }

  if (currentNode === parentElement) {
    return offset
  }

  if (!parentElement.contains(currentNode)) {
    return -1
  }

  while ((prevSibling || currentNode).previousSibling) {
    // Set the new previous sibling
    prevSibling = (prevSibling || currentNode).previousSibling

    // Extract the node text
    nodeContent = prevSibling.innerText || prevSibling.nodeValue || ''

    // Increment the offset
    offset += nodeContent.length
  }

  return offset + getSelectionOffsetRelativeTo(parentElement, currentNode.parentNode)
}

export default {
  getTargetParentNode,
  getSelectionOffsetRelativeTo,
}
