import { getDate, getDateComparator, getFriendlyDate } from '@/mixins/n-time.js'
import { dateTag } from '@/helpers/ag-grid-getters.js'
import useTimelineStore from '@/store/modules/timeline.js'

export default [
  {
    headerName: '',
    field: 'selected',
    width: 40,
    cellRenderer: 'agGroupCellRenderer',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: (params) => {
      const store = useTimelineStore()
      let selectable = true

      Object.values(store.currentIDs.PTLUniqueID).forEach((element) => {
        if (parseInt(element) === parseInt(params.data?.LinkedToID)) {
          selectable = false
        }
      })

      return selectable
    },
    showDisabledCheckboxes: true,
  },
  {
    headerName: 'Date',
    rowDrag: true,
    field: 'FileDate',
    filter: 'agDateColumnFilter',
    sort: 'desc',
    sortingOrder: ['desc', 'asc'],
    width: 120,
    suppressSizeToFit: true,
    tooltipField: 'DateTag',
    filterParams: {
      comparator: getDateComparator,
    },
    valueGetter: (data) => {
      if (data.data && data.data.FileDate) {
        return getDate(data.data.FileDate)
      }
    },
    cellRenderer: (params) => {
      if (params.data && params.data.FileDate) {
        return getFriendlyDate(params.data.FileDate)
      } else if (params.value) {
        return getFriendlyDate(params.value)
      }
    },
  },
  {
    headerName: 'Document ID',
    field: 'SourceDocumentID',
    filter: 'agSetColumnFilter',
    width: 80,
    resizable: true,
    hide: true,
  },
  {
    headerName: 'Date Tag',
    field: 'DateTag',
    filter: 'agSetColumnFilter',
    width: 80,
    resizable: true,
    valueGetter: dateTag,
  },
  {
    headerName: 'Doc Title',
    field: 'DocumentTitle',
    filter: 'agSetColumnFilter',
    width: 150,
    resizable: true,
    hide: false,
  },
  {
    headerName: 'Doc Category',
    field: 'DocumentCategory',
    filter: 'agSetColumnFilter',
    width: 70,
    resizable: true,
    hide: false,
  },
  {
    headerName: 'Linked To',
    field: 'LinkedTo',
    resizable: true,
    width: 150,
    filter: 'agSetColumnFilter',
    valueGetter: (api) => {
      return api?.data?.LinkedTo || 'Pathway'
    },
  },
  {
    headerName: 'Type',
    field: 'LetterType',
    resizable: true,
    filter: 'agSetColumnFilter',
    width: 70,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Rova Status',
    field: 'ClassificationStatusLabel',
    width: 110,
    filter: 'agSetColumnFilter',
  },
  {
    headerName: 'Rova Version',
    field: 'RovaVersion',
    width: 130,
    filter: 'agSetColumnFilter',
    hide: true,
  },
  {
    headerName: 'Specialty Code',
    field: 'ActivitySpecialty',
    resizable: true,
    filter: 'agSetColumnFilter',
    width: 100,
  },
  {
    headerName: 'Linked To ID',
    field: 'LinkedToID',
    resizable: true,
    width: 150,
    tooltipField: 'LinkedTo',
  },
  {
    headerName: 'Pathway ID',
    field: 'PathwayID',
    resizable: true,
    width: 150,
    hide: true,
  },
  {
    headerName: 'Processed On',
    field: 'ProcessedOn',
    filter: 'agDateColumnFilter',
    sortingOrder: ['desc', 'asc', null],
    filterParams: {
      comparator: getDateComparator,
    },
    valueGetter: (data) => {
      if (data.data && data.data.ProcessedOn) {
        return getDate(data.data.ProcessedOn)
      }
    },
    cellRenderer: (params) => {
      if (params.data && params.data.ProcessedOn) {
        return getFriendlyDate(params.data.ProcessedOn)
      } else if (params.value) {
        return getFriendlyDate(params.value)
      }
    },
  },
  {
    headerName: 'Path',
    field: 'Path',
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: 'PTLUniqueID ',
    field: 'PTLUniqueID',
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: 'FileNumber',
    field: 'FileNumber',
    filter: false,
    resizable: false,
    hide: true,
  },
  {
    headerName: 'FileId',
    field: 'FileId',
    filter: 'agTextColumnFilter',
    hide: true,
  },
]
