<template>
  <div class="document-validation-context">
    <a-modal v-model:open="open" :title="modalTitle" @ok="handleModalSubmission">
      <hr />
      <a-form
        data-cy="document-validation-modal"
        ref="validationForm"
        :model="formState"
        name="basic"
        autocomplete="off"
        @finishFailed="onFinishFailed"
      >
        <a-form-item
          name="ClassifierChangedTo"
          :rules="[{ required: true, message: 'Please enter a reason!' }]"
          v-if="selection.action === 'change'"
        >
          <a-row>
            <a-col :span="10">
              <a-row
                ><div class="is-size-6 mb-1">{{ $t('Change From') }}</div></a-row
              >
              <a-row class="is-size-5">{{ classificationData.classification.label }}</a-row>
            </a-col>
            <a-col :span="14">
              <a-row
                ><h4>
                  <div class="is-size-6 mb-1">{{ $t('Change To') }}</div>
                </h4></a-row
              >
              <a-row data-cy="validation-modal-change-to">
                <input-autocomplete-dropdown
                  class="validation-change-dropdown"
                  v-model:value="formState.ClassifierChangedTo"
                  data-cy="validation-modal-change-to-dropdown"
                  :data-source="selection.options"
                />
              </a-row>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item
          name="ValidationRejectionReason"
          :rules="[{ required: true, message: 'Please enter a reason!' }]"
          v-if="selection.action === 'reject'"
        >
          <div class="is-size-6 mb-1">{{ $t('Rejection Reason') }}</div>
          <input-autocomplete-dropdown
            v-model:value="formState.ValidationRejectionReason"
            data-cy="validation-modal-reject-reason-dropdown"
            :data-source="selection.options"
          />

          <a-row class="mt-4" v-if="formState.ValidationRejectionReason">
            <a-col v-if="rejectSelectedItem.description" class="reject-row">
              <a-row>
                <div class="is-size-6 mb-1">{{ $t('Rejection Description') }}</div>
              </a-row>
              <a-row class="has-text-grey">{{ rejectSelectedItem.description }}</a-row>
            </a-col>
            <a-col v-if="rejectSelectedItem.example" class="reject-row">
              <a-row>
                <div class="is-size-6 mb-1">{{ $t('Rejection Example') }}</div>
              </a-row>
              <a-row class="has-text-grey">{{ rejectSelectedItem.example }}</a-row>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item
          name="ValidationComment"
          :rules="[
            {
              required: formState.ValidationRejectionReason === 'Other',
              message: 'Please input a comment!',
            },
          ]"
        >
          <div class="is-size-6 mb-1">Comment</div>
          <a-textarea
            v-model:value="formState.ValidationComment"
            data-cy="validation-modal-text-area"
            :rows="6"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import InputAutocompleteDropdown from '@/components/inputs/input-autocomplete-dropdown.vue'
import useValidationStore from '@/store/modules/validation'
import useConfigStore from '@/store/modules/config'
import { reactive, ref, defineProps, computed, watch } from 'vue'
import useAbility from '@/services/ability.js'
import _ from 'lodash'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'

const formState = reactive({})

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo)
}

const props = defineProps({
  classificationData: {
    type: Object,
    default: null,
  },
})

const documentNLPMixin = useDocumentNLPMixin()

const validationStore = useValidationStore()
const ability = useAbility()
const configStore = useConfigStore()
const validationForm = ref(null)
const submitted = ref(false)
const selectedForm = reactive({})
const submissionLoading = ref(false)
const open = ref(false)
const selection = ref({})

watch(
  () => open.value,
  (value) => {
    documentNLPMixin.setMainScrolling(value)
  },
)

const showModal = (validationMode) => {
  selectedForm.value = validationMode

  if (validationMode === 'change') {
    const classifierValues = validationStore.getContextOptions(
      'classification',
      ability.can('viewAll', 'context:validation'),
    )

    selection.value = {
      action: 'change',
      options: classifierValues[0].options,
    }

    // Removes current classifier from dropdown menu
    // selection.value.options.forEach((category) => {
    //   category.options = category.options.filter(
    //     (c) => c.longName !== props.classificationData.classification.label
    //   )
    // })
  } else if (validationMode === 'reject') {
    selection.value = {
      action: 'reject',
      options: configStore.getConfigByKey('web-config.dropdowns.validationRejectionReasons'),
    }
  }

  open.value = true
}

const modalTitle = computed(() => {
  if (!selectedForm.value) return ''
  return `Validate: ${_.capitalize(selectedForm.value)} Classification`
})

const classificationId = computed(() => {
  return props.classificationData.classification._id
})

const rejectSelectedItem = computed(() => {
  return (
    selection.value.options[0].options.find(
      (el) => el.value === formState.ValidationRejectionReason,
    ) || {}
  )
})

const handleModalSubmission = async () => {
  try {
    await validationForm.value.validate()
  } catch (error) {
    console.log('validation failed')
    return
  }

  // Set modal to loading state
  submissionLoading.value = true

  if (selection.value.action === 'reject') {
    submitted.value = true

    await validationStore.rejectValidation({
      ...formState,
      FileNumber: props.classificationData.fileNumber,
      FileId: props.classificationData.fileId,
      RovaVersion: props.classificationData.rovaVersion,
      ClassificationId: classificationId.value,
    })
  } else if (selection.value.action === 'change') {
    await validationStore.changeValidation({
      FileId: props.classificationData.fileId,
      ClassificationId: classificationId.value,
      RovaVersion: props.classificationData.rovaVersion,
      FileNumber: props.classificationData.fileNumber,
      ...formState,
    })
  }

  open.value = false

  // Delete all keys from formState
  Object.keys(formState).forEach((key) => delete formState[key])
}

defineExpose({
  openModal: showModal,
})
</script>

<style scoped>
.cm-ul > li {
  line-height: 32px !important;
  font-size: 12px !important;
}
.reject-row {
  padding-bottom: 10px;
}
</style>
