import { api } from '@/services/http'

export const activityService = {
  getDetails(params) {
    return api.post('/activity/details', params)
  },
  getRTTData(params) {
    return api.post('/activity/rtt-data', params)
  },
  getScenarioData(params) {
    return api.get('/activity/get-scenario-data', params)
  },
}
