import config from '@/config'
import ls from '@/helpers/localStorage'

const isNC = () => {
  // If debug flag nc-logs is detected show logs
  return ls.get('nc-logs') || config.IS_DEVELOPMENT
}

const info = (...args) => {
  if (isNC()) {
    console.info(args) // eslint-disable-line no-console
  }
}

const warn = (...args) => {
  if (isNC()) {
    console.warn(args) // eslint-disable-line no-console
  }
}

const error = (...args) => {
  if (isNC()) {
    console.error(args) // eslint-disable-line no-console
  }
}

export default {
  info,
  warn,
  error,
}
