<template>
  <div class="document-preview-iframe">
    <!-- @see: https://stackoverflow.com/a/60539836/335366 -->
    <!-- https://stackoverflow.com/questions/13432821/is-it-possible-to-add-request-headers-to-an-iframe-src-request -->
    <resource-not-found-404 v-if="notFound" :text="notFound" />
    <n-loading-spinner v-if="!iframeUrl && !notFound" class="spinner" />
    <iframe
      v-if="iframeUrl && !notFound"
      :src="iframeUrl"
      frameBorder="0"
      scrolling="auto"
      height="650"
      width="100%"
    />
  </div>
</template>

<script setup>
import config from '@/config'
import { getApi } from '@/services/http'
import ResourceNotFound404 from '@/components/errors/resource-not-found-404.vue'
import NLoadingSpinner from '@/components/ui/n-loading/n-loading-spinner.vue'
import { defineProps, ref, watch } from 'vue'

const props = defineProps({
  file: {
    type: Object,
    required: true,
  },
})

const iframeUrl = ref()

const getPDFUrl = async () => {
  const api = getApi({
    responseType: 'blob',
  })

  try {
    const response = await api.get(
      `${config.apiUrl}document/download/?FileNumber=${props.file.FileNumber}&FileId=${props.file.FileId}`,
    )

    const dataUrl = URL.createObjectURL(response)
    notFound.value = null
    return dataUrl
  } catch (error) {
    console.error(error)
    if (error.response.status === 404) {
      console.error(error.response)
      notFound.value = 'The file could not be found.'
    }
    return null
  }
}

getPDFUrl().then((url) => {
  iframeUrl.value = url
})

const notFound = ref(null)

watch(props.file, async () => {
  const url = await getPDFUrl()
  iframeUrl.value = url
})
</script>
<style lang="less">
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
