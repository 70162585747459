<script setup>
import { ref, watch, onMounted } from 'vue'
import useFilterStore from '@/store/modules/filter'
import usei18nStore from '@/store/modules/i18n'
import useConfigStore from '@/store/modules/config'
import useUIStore from '@/store/modules/ui'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()

const configStore = useConfigStore()
const filterStore = useFilterStore()
const i18nStore = usei18nStore()
const uiStore = useUIStore()

const initialised = ref(false)

const route = useRoute()

const handleEvent = (e) => {
  // For embedded applications, we need to update the app params when the pathway changes
  if (typeof e.data === 'object') return

  const params = JSON.parse(e.data)
  if (params.eventName === 'surveyor-pathway-changed') {
    delete params.eventName
    // Set the app params in the config store
    configStore.setAppParams(params)
  }
}

onMounted(() => {
  window.addEventListener('message', handleEvent, false)
})

// route.query is sometimes populated before we set the app params (and before watch is set up), sometimes after -
// so we need to both set it initially, and watch for changes
watch(
  () => route.query,
  (query) => {
    configStore.setAppParams(query)
  },
)
configStore.setAppParams(route.query)

const calls = [
  configStore.fetchConfig(),
  i18nStore.fetchLocale('en'),
  uiStore.loadDefaultValues(),
  configStore.fetchPermissions(),
]

Promise.all(calls)
  .then(() => {
    filterStore.overwriteFilterOptions(
      configStore.getConfigByKey('application-config.filtersList') || [],
    )
    filterStore.overwriteFilterGroups(
      configStore.getConfigByKey('application-config.filterGroups') || [],
    )

    initialised.value = true
  })
  .catch((error) => {
    console.error(error)
    router.push({ name: '401' })
    initialised.value = true
  })

// const loading = ref(false)
</script>

<template>
  <div id="root" class="gx-app-layout ant-layout ant-layout-has-sider">
    <!-- Overrides the default colour for ant design -->
    <a-config-provider
      :theme="{
        token: {
          colorPrimary: '#072942',
        },
      }"
    >
      <router-view v-if="initialised" />
    </a-config-provider>
  </div>
</template>

<style>
body {
  margin: 0px;
  min-height: 100vh;
}
</style>
