<template>
  <div class="document-reader-item">
    <div
      v-if="selectedPreviewType === 'text' && renderComponent"
      class="document-reader"
      @contextmenu.prevent.stop="handleContextToggle"
    >
      <document-classification-context-menu
        ref="contextMenu"
        :document="props.model"
        :highlighted-attributes="highlightAttributes"
      />

      <span
        v-for="(component, index) in content"
        :key="'component' + index"
        data-cy="document-section-item"
        class="document-section document-section-item"
      >
        <component :is="component.name" v-if="component.name" v-bind="component.props" />
        <span v-else v-html="component" />
      </span>
    </div>
    <div v-if="selectedPreviewType === 'pdf'">
      <document-preview-iframe :file="model" style="min-height: 800px" />
    </div>
  </div>
</template>

<script setup>
import { forEach, clone, find, flatten, includes, orderBy } from 'lodash'
import useDocumentNLPMixin from '@/mixins/documentNLPMixin'
import DocumentPreviewIframe from '@/components/documents/document-preview-iframe/document-preview-iframe.vue'
import DocumentClassificationContextMenu from '@/components/document-nlp/document-classification-context-menu/document-classification-context-menu.vue'
import { captureException } from '@sentry/vue'
import { escapeRxSpecialChars, wrapNth } from '@/helpers/document'
import { defineProps, ref, computed, nextTick } from 'vue'
import { Buffer } from 'buffer/'
import DocumentContentTag from '@/components/document-nlp/document-content-tag/document-content-tag.vue'
import DOMRangeHelper from '@/helpers/dom-range-helper'
import useConfigStore from '@/store/modules/config'

const documentNLPMixin = useDocumentNLPMixin()
const configStore = useConfigStore()

const props = defineProps({
  model: {
    type: Object,
    required: true,
  },
  previewType: {
    type: String,
    default: 'text',
  },
  enabledTags: {
    type: Array,
    default: () => {
      return []
    },
  },
})

const renderComponent = ref(true)
const highlightAttributes = ref()
const contextMenu = ref(null)

// const intents = reactive({
//   FollowUp: 'grey',
//   Discharge: '#6accb2'
// })

const rawText = computed(() => {
  return props.model.text && props.model.text !== '' ? props.model.text : props.model.TextContent
})

const content = computed(() => {
  if (!rawText.value) {
    return []
  }

  let documentText = highlightQuery(rawText.value)

  const components = highlightSentence(documentText)

  // This ensures full-stops are not split from tag sentences into the next component
  // if the full stop is not included in the sentence returned in the Rova Model
  components.forEach((component, index) => {
    let nextComponent = components[index + 1]

    // Check if component is an match object and nextComponent is a string that starts with a full stop
    if (component.props && typeof nextComponent === 'string' && nextComponent.startsWith('.')) {
      // Add the full stop to the end of currentComponent.props.text
      component.props.text += '.'

      // Remove the full stop from the beginning of nextComponent
      components[index + 1] = nextComponent.substring(1)
    }
  })

  forceRerender()

  return components
})

const selectedPreviewType = computed(() => {
  if (!rawText.value) {
    return 'pdf'
  }

  return props.previewType
})
const forceRerender = () => {
  // Remove my-component from the DOM
  renderComponent.value = false

  nextTick(() => {
    // Add the component back in
    renderComponent.value = true
  })
}

const handleContextToggle = () => {
  if (
    documentNLPMixin.configStore.getConfigByKey(
      'application-config.features.enableDocumentClassification',
    )
  ) {
    const highlightedSelection = window.getSelection()

    if (highlightedSelection) {
      // Get the highlighted text attributes
      const documentReaderNode = DOMRangeHelper.getTargetParentNode(
        highlightedSelection.getRangeAt(0),
        'document-reader',
      )
      const offset = DOMRangeHelper.getSelectionOffsetRelativeTo(documentReaderNode)
      const highlightedText = highlightedSelection.toString()

      highlightAttributes.value = {
        Phrase: highlightedText,
        Offset: {
          offset: offset,
          wordStart: offset,
          wordEnd: offset + highlightedText.length,
          length: highlightedText.length,
        },
      }

      // Open the context menu
      contextMenu.value.openModal()
    }
  }
}

const highlightQuery = (inputText) => {
  // Highlight query text if the highlight query param is present
  const highlightBase64 = configStore.appParams.highlight

  if (highlightBase64) {
    const highlightText = Buffer.from(highlightBase64, 'base64').toString('ascii')
    return inputText.replace(highlightText, `<mark>${highlightText}</mark>`)
  }

  return inputText
}

const highlightSentence = (text) => {
  let components = []
  let documentHTML = text.replace(/(?:\r\n|\r|\n)/g, '<br>')

  if (!props.model.classifications) {
    return [documentHTML]
  }

  const matchedClassifiers = []

  const segmentsIntersect = (x1, x2, y1, y2) => {
    return x2 >= y1 && y2 >= x1
  }

  const groups = []

  const original = clone(props.model.classifications).filter((elm) => {
    return elm.result !== 'negative'
  })

  const unwindedClassifications = []

  /**
   * Combines Classifiers with Matches (to access all parameters?)
   */
  forEach(original, (classification) => {
    // Don't parse negative classifications
    if (classification.result === 'negative') {
      return false
    }

    forEach(classification.parsedMatches, (match) => {
      unwindedClassifications.push({
        classification,
        ...match,
      })
    })
  })

  /**
   * Construct Grouped Classififers
   */

  forEach(unwindedClassifications, (elm) => {
    // Check if this item intersects with any other in the array
    forEach(unwindedClassifications, (elm2) => {
      // Ensure we are not comparing the same elements
      if (elm.id !== elm2.id) {
        const hasIntersection = segmentsIntersect(
          elm.offset,
          elm.offset + elm.length - 1,
          elm2.offset,
          elm2.offset + elm2.length - 1,
        )

        // No intersection SKIP
        if (!hasIntersection) {
          return true
        }

        // If an intersection exists
        // 1. Check if elm2 is already in a group.
        // 2. If both are already in the same group, then SKIP
        // 2. If elm2 is not in a group, add both elm1+2 into a new group
        // 3. If elm2 is in a group, simply append elm1 into that group.
        const groupMatch = find(groups, (group) => {
          const hasMatch = find(group, { id: elm2.id })

          if (hasMatch) {
            return group
          }
          return false
        })

        if (groupMatch) {
          // Check if both already exist in the found group, if so SKIP
          if (find(groupMatch, { id: elm.id })) {
            return true
          }

          // Otherwise push elm1 to this group
          groupMatch.push({
            ...elm,
            classificationId: elm.classification._id,
          })
        } else {
          // Make sure that elm1 does not belong to a group already
          let exists = false

          forEach(groups, (group) => {
            const existsInGroup = find(group, { id: elm.id })

            if (existsInGroup) {
              exists = true
              return
            }
          })

          if (!exists) {
            // At this point no group match exists, so we create one
            groups.push([
              {
                ...elm,
                classificationId: elm.classification._id,
              },
              {
                ...elm2,
                classificationId: elm2.classification._id,
              },
            ])
          }
        }
      }
      return true
    })
  })

  /**
   * Process Grouped Classifiers
   **/
  forEach(groups, (group) => {
    let sentence = '',
      match

    // NOTE: classifier with longest name is used.
    forEach(group, (elm) => {
      if (elm.length > sentence.length) {
        sentence = elm.sentence
        match = elm
      }
    })

    const classifier = documentNLPMixin.getClassifier(match.classification.classifier)

    matchedClassifiers.push({
      sentence,
      classifier,
      classifications: group.map((g) => g.classification),
      position: match.offset,
      sentenceCountInDoc: match.sentenceCountInDoc,
      sentenceIndexInDoc: match.sentenceIndexInDoc,
    })
  })

  /**
   * Process single classifiers
   **/
  forEach(original, (classification) => {
    forEach(classification.parsedMatches, (match) => {
      // This creates an array of compontents & raw strings. It splits out the text string and
      // for each classification, creates an object that is used to dynamically render the vue component.
      // Make sure that the item does not belong
      const groupIds = flatten(groups.map((group) => group.map((item) => item.id)))

      if (includes(groupIds, match.id)) {
        return false
      }

      if (match.sentence) {
        const classifier = documentNLPMixin.getClassifier(classification.classifier)

        matchedClassifiers.push({
          sentence: match.sentence,
          classifier,
          classifications: [classification],
          position: match.offset,
          sentenceCountInDoc: match.sentenceCountInDoc,
          sentenceIndexInDoc: match.sentenceIndexInDoc,
        })
      }
    })
  })

  /**
   * Replaces matched classifiers at their correct sentence index with a special text to be matched
   */
  matchedClassifiers.forEach((match) => {
    let needle = match.sentence.trim()

    documentHTML = wrapNth(documentHTML, needle, '#MATCH#', match.sentenceIndexInDoc)
  })

  /**
   * Loop through matched classifiers which are sorted by position
   * These need to be sorted by position in order to match all classifiers
   * This outputs an array of text / vue components which.
   */
  orderBy(matchedClassifiers, 'position').forEach((match) => {
    let needle = match.sentence.trim()

    /**
     * Capture the text between the "#MATCH#" tags from the HTML
     * to use as the separator in the `join()` below. This ensures
     * that the text retains its original casing in the rendered output,
     * as opposed to using the original `needle` variable which may be lowercased.
     * See: https://dev.azure.com/MBI-Healthcare/LUNA-Apps/_workitems/edit/11200
     */
    const matchBy = `#MATCH#(${escapeRxSpecialChars(needle)})#MATCH#`
    const matchedPhrase = documentHTML.match(new RegExp(matchBy, 'ig'))

    // Split text by matched phrase
    const splitBy = `#MATCH#${escapeRxSpecialChars(needle)}#MATCH#`
    const textSplit = documentHTML.split(new RegExp(splitBy, 'ig'))

    // If there is no match, or the split is only 1 item, then skip as there is nothing to replace
    if (textSplit.length === 1 || matchedPhrase === null) {
      captureException(
        new Error(`No matching tag found for FileId: ${props.model.FileId}, skipping`),
      )
      return
    }

    const replacement = matchedPhrase[0].replace(/#MATCH#/g, '')

    // Add next split content to components & remove from documentHTML
    components.push(textSplit.shift())

    // Join up remaining text
    documentHTML = textSplit.join(replacement)

    const finalNeedleHtml = highlightQuery(replacement)
    components[components.length] = {
      name: DocumentContentTag,
      props: {
        classifications: match.classifications,
        fileId: props.model.uuid,
        fileNumber: props.model.FileNumber,
        text: finalNeedleHtml,
        rovaVersion: props.model.RovaVersion,
        textColour: match.classifier.colour,
      },
    }
  })

  // Adds the final string to the array of components
  components.push(documentHTML)
  return components
}
</script>

<style scoped>
.document-reader {
  line-height: 25px;
  font-size: 14px;
}
</style>
