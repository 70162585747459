<template>
  <div v-if="pathwayData && !isMultiple" data-cy="pathway-info-panel" class="columns pt-2">
    <div class="column pb-1">
      <n-tag
        data-cy="pathway-info-census-date-tag"
        icon="calendar"
        label="Census Date"
        :value="timelineStore.censusDate"
      />
      <n-tag data-cy="pathway-info-id-tag" icon="key" label="Pathway ID" :value="pathwayData.ID" />
      <n-tag
        data-cy="pathway-info-start-date-tag"
        icon="calendar"
        label="Start Date"
        :value="getFriendlyDate(pathwayData['Start Date'])"
      />
      <n-tag
        v-if="pathwayData['Start Date']"
        data-cy="pathway-info-end-date-tag"
        icon="calendar"
        label="End Date"
        :value="getFriendlyDate(pathwayData['Stop Date'] || 'None')"
      />
      <n-tag
        data-cy="pathway-info-specialty-tag"
        icon="briefcase-medical"
        label="Specialty"
        :value="pathwayData.Specialty"
      />
      <n-tag
        data-cy="pathway-info-clinician-tag"
        icon="stethoscope"
        label="Clinician"
        :value="pathwayData.Clinician"
      />
    </div>
  </div>

  <div
    v-else-if="isMultiple"
    class="multiple-tag pt-2"
    data-cy="pathway-info-panel-multiple"
    @click="openPatientMasterRecords"
  >
    <n-tag
      data-cy="pathway-info-multiple-tag"
      icon="key"
      label="Pathway ID"
      value="Multiple"
      value-style="link"
      :click-to-copy="false"
    />
  </div>
</template>

<script setup>
import useTimelineStore from '@/store/modules/timeline'
import patientService from '@/services/endpoints/patient'
import NTag from '@/components/ui/n-tag/n-tag.vue'
import dayjs from 'dayjs'
import { computed, ref, onMounted, watch } from 'vue'
import { getFriendlyDate } from '@/mixins/n-time'
import useUIStore from '@/store/modules/ui'

const uiStore = useUIStore()
const timelineStore = useTimelineStore()

const pathwayData = ref(null)
const currentIDs = ref(timelineStore.currentIDs)

const currentPTLUniqueID = computed(() => {
  if (!currentIDs.value.PTLUniqueID) {
    return null
  }
  return currentIDs.value.PTLUniqueID[0][0]
})

const isMultiple = computed(() => {
  return currentIDs.value.PTLUniqueID?.length > 1
})

onMounted(() => {
  fetchPathwayInfo()
  fetchCensusDate()
})

const fetchPathwayInfo = () => {
  patientService.getPathwayInfo(currentPTLUniqueID.value).then((resp) => {
    pathwayData.value = resp[0]
  })
}

// For embedded applications, we need to update pathway data when the pathway changes
watch(
  () => currentIDs.value.PTLUniqueID,
  (newValue, oldValue) => {
    if (newValue !== oldValue && oldValue) {
      // Fetch pathway data when a new PTLUniqueID is set
      fetchPathwayInfo()
    }
  },
)

const fetchCensusDate = () => {
  patientService.getCensusDate().then((resp) => {
    const date = dayjs(resp)
    if (!isNaN(date)) {
      // Add census date to the timeline store
      timelineStore.censusDate = date.format('DD/MM/YYYY')
    }
  })
}

const openPatientMasterRecords = () => {
  uiStore.modalApis.patientMasterRecords.open()
}
</script>
<style scoped>
.multiple-tag * {
  pointer-events: none !important;
}
</style>
