<template>
  <span :class="`icon-${icon[1]} ${banned ? 'fa-stack' : ''}`">
    <span v-if="text" class="text-icon">
      {{ text }}
    </span>
    <i v-else :class="[cssClass, { grey: banned }, iconClass]" class="fa-icon main-icon"></i>
    <i v-if="banned" class="fa-icon fa-stack-1x fal fa-ban banned-icon" style="color: Tomato"></i>
  </span>
</template>

<script setup>
import { defineProps, ref } from 'vue'

const props = defineProps({
  icon: {
    type: Array,
    required: false,
    default: null,
  },
  size: {
    type: String,
    default: null,
  },
  cssClass: {
    type: String,
    default: null,
  },
  banned: {
    type: Boolean,
    default: false,
  },
})

const iconClass = ref()
const text = ref('')

if (props.icon[1].includes('alphabet')) {
  iconClass.value = null
  text.value = props.icon[1].split('-')[1]
} else {
  iconClass.value = `${props.icon[0]} fa-${props.icon[1]}`
}
</script>
<style lang="css">
.text-icon {
  font-size: 1.3em;
  font-weight: bold;
}
.banned-icon {
  display: grid;
  place-items: center;
  font-size: 1.6em;
}

.vis-item-content .fa-stack {
  padding: 0 4px;
}
</style>
