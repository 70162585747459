<template>
  <a-select
    class="certain-category-search"
    popup-class-name="certain-category-search-dropdown"
    :dropdown-match-select-width="500"
    style="width: 250px"
    :options="props.dataSource"
  >
    <template #option="item">
      <template v-if="item.options">
        <span>
          {{ item.value }}
        </span>
      </template>
      <template v-else>
        <span :class="item.example ? 'reject-options' : ''" class="long-name">{{
          item.label || item.value
        }}</span>
        <span class="description">{{ item.description }}</span>
      </template>
    </template>
  </a-select>
</template>
<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  dataSource: {
    type: Array,
    required: true,
  },
})
</script>
<style>
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
</style>

<style scoped lang="less">
.certain-category-search-wrapper
  :deep(.certain-category-search.ant-select-auto-complete)
  .ant-input-affix-wrapper
  .ant-input-suffix {
  right: 12px;
}
.certain-category-search-wrapper :deep(.certain-search-item-count) {
  position: absolute;
  color: #999;
  right: 16px;
}
.certain-category-search-wrapper
  :deep(.certain-category-search.ant-select-focused)
  .certain-category-icon {
  color: #108ee9;
}
.certain-category-search-wrapper :deep(.certain-category-icon) {
  color: #6e6e6e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}

.long-name::after {
  content: '\a';
  white-space: pre;
}
.ant-select-item-group span {
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px;
}
.reject-options {
  font-weight: 700;
  font-size: 14px;
}
</style>
