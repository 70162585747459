import _ from 'lodash'
import useFilterStore from '@/store/modules/filter'
import useTimelineStore from '@/store/modules/timeline'

export default () => {
  const filterStore = useFilterStore()
  const timelineStore = useTimelineStore()

  const friendlyPredicateValue = (value, type) => {
    if (!value) {
      return ''
    }
    if (type === 'number' && parseInt(value) > 0) {
      return parseInt(value)
    }
    if (type === 'array') {
      return value.join()
    }
    if (type === 'date' && typeof value === 'object') {
      return value.format('Mo MMM YYYY')
    }
    return value
  }

  const checkAffix = (filterAttr, type) => {
    const item = getFilterOptionByAttr(filterAttr)

    if (!item) {
      return false
    }
    if (!_.isUndefined(item.affix) && !_.isUndefined(item.affix[type])) {
      return item.affix[type]
    }
  }

  const getFilterOptionByAttr = (attr) => {
    return _.find(filterStore.filterTypeOptions, { attr: attr })
  }

  const getFilterByAttr = (attr) => {
    return _.find(filterStore.filtersList, { attr: attr })
  }

  const getFilterTypeByAttr = (attr) => {
    const value = _.find(filterStore.filterTypeOptions, { attr: attr })

    return value ? value.name : '...'
  }

  const removeGroupPredicate = (groupId, predicateId) => {
    timelineStore.timelineHeaderFilterChanged = true
    timelineStore.dateSliderChanged = false

    filterStore.removeGroupPredicate({ groupId: groupId, predicateId: predicateId })
  }

  const changeSegmentType = (type) => {
    filterStore.setSegmentType({ type })
  }

  const changeConditionType = (type, segment) => {
    filterStore.setConditionType({ type, segment })
  }

  const updatePredicate = (data, groupId, predicateId) => {
    timelineStore.timelineHeaderFilterChanged = true
    timelineStore.dateSliderChanged = false

    filterStore.updateGroupPredicate({ data, groupId, predicateId })
  }

  const addConditionPredicate = (segment, conditionAttr) => {
    const filter = getFilterByAttr(conditionAttr)
    const condition = {
      attr: conditionAttr,
      type: filter.type,
      value: filter.default || null,
      metaType: filter.metaType || null,
      comparison: 'more_than',
    }

    filterStore.addGroupPredicate({ segment, condition })
  }

  const addGroup = (conditionAttr) => {
    const filter = getFilterByAttr(conditionAttr)
    const condition = {
      type: 'and',
      predicates: [
        {
          attr: conditionAttr,
          type: filter.type,
          value: filter.default || null,
          comparison: 'more_than',
          metaType: filter.metaType || null,
        },
      ],
    }
    timelineStore.timelineHeaderFilterChanged = true
    timelineStore.dateSliderChanged = false

    filterStore.addCondition(condition)
  }

  return {
    friendlyPredicateValue,
    checkAffix,
    getFilterOptionByAttr,
    getFilterByAttr,
    getFilterTypeByAttr,
    removeGroupPredicate,
    changeSegmentType,
    changeConditionType,
    updatePredicate,
    addConditionPredicate,
    addGroup,
  }
}
