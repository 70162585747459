<template>
  <n-panel>
    <template #help>
      <div v-html="$t('vis-timeline-tooltip-help')" />
    </template>
    <template #actions>
      <div class="columns mb-3 mt-0">
        <div class="column is-narrow">
          <n-tooltip :text="$t('Group By')" placement="bottom" class="mx-2">
            <a-select
              :value="uiStore.state.visTimelineGroupMode"
              class="group-select"
              style="width: 155px"
              :options="timelineOptions.visTimelineGroupOptions"
              @change="uiStore.updateUserDefaults($event, 'visTimelineGroupMode')"
            />
          </n-tooltip>
          <n-tooltip :text="$t(`Order: ${getSortOrderLabel()}`)" placement="bottom">
            <a-button @click="cycleSortMode">
              <span v-if="uiStore.state.visTimelineOrderMode === 'none'">-</span>
              <span v-else-if="uiStore.state.visTimelineOrderMode === 'asc'">↑</span>
              <span v-else-if="uiStore.state.visTimelineOrderMode === 'desc'">↓</span>
            </a-button>
          </n-tooltip>
        </div>
        <div class="column is-narrow pl-0">
          <n-tooltip :text="$t('Select Timeline Options')" placement="top">
            <Multiselect
              :options="timelineOptions.visTimelineOptions"
              data-cy="timeline-options-multiselect"
              class="timeline-multiselect m-0"
              :closeOnSelect="false"
              placeholder="Options"
              mode="multiple"
              :canClear="false"
              :hideSelected="false"
              :multipleLabel="getMultipleLabel"
              v-model="uiStore.state.visSelectedTimelineOptions"
              @select="handleOptionChange(true, $event)"
              @deselect="handleOptionChange(false, $event)"
            >
              <template #option="{ option, selected }">
                <span :class="{ 'is-selected': selected }">{{ option.label }}</span>
              </template>
            </Multiselect>
          </n-tooltip>
        </div>
      </div>
    </template>
    <template #actions-mid>
      <div class="timeline-zoom-controls mt-3">
        <div class="n-flex n-mb-1">
          <n-tooltip :text="$t('Previous')" placement="top">
            <a-button
              data-cy="timeline-zoom-previous-button"
              class="previous-button n-m-0"
              shape="circle"
              @click="setRangeOffset(-1, 'visRangeOffset')"
            >
              <i class="fal fa-chevron-left" />
            </a-button>
          </n-tooltip>
          <n-tooltip :text="$t('Zoom Level')" placement="bottom">
            <a-radio-group
              data-cy="timeline-zoom-buttons"
              :value="uiStore.state.visZoomMonths.toString()"
              button-style="solid"
              @change="setZoomFilters($event.target.value, 'visZoomMonths')"
            >
              <a-radio-button class="first-radio-button n-m-0" value="1">1m</a-radio-button>
              <a-radio-button class="n-m-0" value="3">3m</a-radio-button>
              <a-radio-button class="n-m-0" value="6">6m</a-radio-button>
              <a-radio-button class="n-m-0" value="12">1y</a-radio-button>
              <a-radio-button class="n-m-0" value="24">2y</a-radio-button>
              <a-radio-button class="last-radio-button n-m-0" value="0">All</a-radio-button>
            </a-radio-group>
          </n-tooltip>
          <n-tooltip :text="$t('Next')" placement="top">
            <a-button
              data-cy="timeline-zoom-next-button"
              class="next-button n-m-0 n-mr-1"
              shape="circle"
              @click="setRangeOffset(1, 'visRangeOffset')"
            >
              <i class="fal fa-chevron-right" />
            </a-button>
          </n-tooltip>
          <n-tooltip :text="$t('Toggle First/Last Zoom')" placement="bottom">
            <a-switch
              data-cy="timeline-zoom-first-last-switch"
              class="first-last n-mb-0 n-mr-1"
              checked-children="Last"
              un-checked-children="First"
              :checked="uiStore.state.visZoomLast"
              @change="setZoomFilters($event, 'visZoomLast')"
            />
          </n-tooltip>
        </div>
      </div>
    </template>
    <template #content>
      <vis-timeline />
      <div class="timeline-overlay">
        <div class="timeline-overlay--content">loading...</div>
      </div>
    </template>
  </n-panel>
</template>

<script setup>
import NPanel from '@/components/ui/n-panel/n-panel.vue'
import VisTimeline from '@/components/timeline/vis-timeline/vis-timeline.vue'
import useUIStore from '@/store/modules/ui'
import NTooltip from '@/components/ui/n-tooltips/n-tooltip.vue'
import useTimelineStore from '@/store/modules/timeline'
import timelineOptions from '@/config/timeline/vis-timeline-options.js'
import Multiselect from '@vueform/multiselect'

const store = useTimelineStore()
const uiStore = useUIStore()

const handleOptionChange = (isSelected, option) => {
  handleFilterChanged(isSelected, option)
}

const handleFilterChanged = (isSelected, filter) => {
  uiStore.updateUserDefaults(isSelected, filter)
  store.timelineFilterChanged = true
}

const getMultipleLabel = (selected) => {
  return `Options (${selected.length})`
}

function cycleSortMode() {
  const options = timelineOptions.visTimelineOrderOptions.map((option) => option.value)
  const currentIndex = options.indexOf(uiStore.state.visTimelineOrderMode)
  const nextMode = options[(currentIndex + 1) % options.length]

  uiStore.updateUserDefaults(nextMode, 'visTimelineOrderMode')
}

const getSortOrderLabel = () => {
  return timelineOptions.visTimelineOrderOptions.find(
    (option) => option.value === uiStore.state.visTimelineOrderMode,
  ).label
}

const setZoomFilters = ($event, filter) => {
  store.timelineFilterChanged = false
  store.dateSliderChanged = false
  uiStore.updateUserDefaults($event, filter)
}

const setRangeOffset = (offset, filter) => {
  store.timelineFilterChanged = false
  store.dateSliderChanged = false
  uiStore.updateUserDefaults(uiStore.state.visOffsetClicked + 1, 'visOffsetClicked')
  uiStore.updateUserDefaults(offset, filter)
}
</script>

<style lang="less">
.timeline-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #00000050;
  align-items: center;
  z-index: 999;
  justify-content: center;
  opacity: 0;
  display: none;
  &.show {
    display: flex;
    opacity: 1;
  }
  &--content {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
}
.group-select .ant-select-selection-selected-value {
  pointer-events: none;
}
.first-last {
  margin-left: 15px;
}
.vis-item.vis-point.has-border-grey-dark {
  border: 1px solid gray;
  border-radius: 5px;
}
.vis-range.has-background-transparent {
  height: 15px !important;
  top: 7px !important;
}
.timeline-multiselect .multiselect-multiple-label {
  padding-right: 0 !important;
}

.timeline-multiselect .multiselect-dropdown {
  overflow-y: hidden !important;
  border-radius: 0 0 8px 8px !important;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped lang="less">
.zoom-radio-buttons {
  border-radius: 0px !important;
}
.previous-button {
  border-radius: 5px 0px 0px 5px !important;
  border-right: 0px !important;
}
.next-button {
  border-radius: 0px 5px 5px 0px !important;
  border-left: 0px !important;
}
.previous-button,
.next-button {
  background: white !important;
  height: 31px;
}
.first-radio-button,
.last-radio-button {
  border-radius: 0px !important;
}
.first-last {
  align-self: center !important;
}
.timeline-switch {
  margin-right: 5px !important;
}

.multiselect-placeholder {
  --ms-placeholder-color: #000000;
}
.multiselect-option:not(.is-selected):not(.is-pointed) span {
  color: #000000;
  transition: color 0.5s ease;
}
.multiselect {
  position: absolute;
  max-width: 130px;
  font-size: 1em;

  --ms-max-height: 100vh;
  --ms-line-height: 0.8;
  --ms-border-color: #d1d5db;

  --ms-option-color-selected: #ffffff;
  --ms-option-color-pointed: #4d4d4d;
  --ms-option-color-selected-pointed: #ffffff;

  --ms-dropdown-bg: #ffffff;
  --ms-option-bg-selected: #01274c;
  --ms-option-bg-selected-pointed: #02376c;
  --ms-option-bg-pointed: #ffffff;
  transition: color 0.5s ease;
}
</style>
<style lang="less" src="./vis-timeline.less"></style>
