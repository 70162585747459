import { dateTag } from '@/helpers/ag-grid-getters.js'
import { getDate, getDateComparator, getFriendlyDate } from '@/mixins/n-time.js'

export default {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      key: 'totalAndFilter',
      align: 'left',
    },
    { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
    { statusPanel: 'agAggregationComponent', align: 'right' },
  ],
  sidebar: {
    toolPanels: ['columns', 'filters'],
    position: 'right',
  },
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
  defaultToolPanel: '',
  columnDefs: [
    {
      headerName: 'Date',
      field: 'FileDate',
      filter: 'agDateColumnFilter',
      sort: 'desc',
      sortingOrder: ['desc', 'asc'],
      width: 120,
      suppressSizeToFit: true,
      tooltipField: 'DateTag',
      filterParams: {
        comparator: getDateComparator,
      },
      valueGetter: (data) => {
        if (data.data && data.data.FileDate) {
          return getDate(data.data.FileDate)
        }
      },
      cellRenderer: (params) => {
        if (params.data && params.data.FileDate) {
          return getFriendlyDate(params.data.FileDate)
        } else if (params.value) {
          return getFriendlyDate(params.value)
        }
      },
    },
    {
      headerName: 'Document ID',
      field: 'SourceDocumentID',
      filter: 'agTextColumnFilter',
      width: 80,
      resizable: true,
      hide: true,
    },
    {
      headerName: 'Date Tag',
      field: 'DateTag',
      filter: 'agSetColumnFilter',
      hide: true,
      width: 80,
      resizable: true,
      valueGetter: dateTag,
    },
    {
      headerName: 'Doc Title',
      field: 'DocumentTitle',
      filter: 'agSetColumnFilter',
      width: 150,
      resizable: true,
      hide: false,
    },
    {
      headerName: 'Doc Category',
      field: 'DocumentCategory',
      filter: 'agSetColumnFilter',
      width: 70,
      resizable: true,
      hide: false,
    },
    {
      headerName: 'Type',
      field: 'LetterType',
      resizable: true,
      filter: 'agSetColumnFilter',
      width: 50,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Linked To',
      field: 'LinkedTo',
      resizable: true,
      hide: true,
      width: 60,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Pathway ID',
      field: 'PathwayID',
      resizable: true,
      hide: true,
      width: 150,
    },
    {
      headerName: 'Icon',
      field: 'ClassificationStatusIcon',
      filter: false,
      resizable: false,
      width: 30,
      maxWidth: 30,
      cellClass: 'no-pad',
    },
    {
      headerName: 'Rova Status',
      field: 'ClassificationStatusLabel',
      width: 130,
      filter: 'agSetColumnFilter',
      hide: true,
    },
    {
      headerName: 'Rova Version',
      field: 'RovaVersion',
      width: 130,
      filter: 'agSetColumnFilter',
      hide: true,
    },
    {
      headerName: 'Classifier',
      field: 'ClassifierLabel',
      width: 220,
      suppressSizeToFit: true,
      filter: 'agSetColumnFilter',
    },
    {
      headerName: 'Confidence Level',
      field: 'ConfidenceLevel',
      width: 80,
      filter: 'agSetColumnFilter',
      hide: false,
    },
    {
      headerName: 'Processed On',
      field: 'ProcessedOn',
      filter: 'agDateColumnFilter',
      sortingOrder: ['desc', 'asc', null],
      hide: true,
      filterParams: {
        comparator: getDateComparator,
      },
      valueGetter: (data) => {
        if (data.data && data.data.ModifiedTime) {
          return getDate(data.data.ModifiedTime)
        }
      },
      cellRenderer: (params) => {
        if (params.data && params.data.ModifiedTime) {
          return getFriendlyDate(params.data.ModifiedTime)
        } else if (params.value) {
          return getFriendlyDate(params.value)
        }
      },
    },
    {
      headerName: 'Sentence',
      field: 'Sentence',
      filter: 'agTextColumnFilter',
      width: 300,
      resizable: true,
    },
    {
      headerName: 'Phrase',
      field: 'Phrase',
      filter: 'agTextColumnFilter',
      hide: false,
      width: 150,
      resizable: true,
    },
    {
      headerName: 'Pattern',
      field: 'Pattern',
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      headerName: 'Path',
      field: 'Path',
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      headerName: 'FileNumber',
      field: 'FileNumber',
      filter: false,
      resizable: false,
      hide: true,
    },
    {
      headerName: 'FileId',
      field: 'FileId',
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      headerName: 'Negated By',
      field: 'negatedBy',
      filter: 'agTextColumnFilter',
    },
  ],
}
