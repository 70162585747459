export default {
  flex: true,
  minWidth: 100,
  filter: 'agTextColumnFilter',
  sortable: true,
  editable: false,
  resizable: true,
  enableValue: true,
  enableRowGroup: true,
  enablePivot: true,
  floatingFilter: true,
}
