import { createRouter, createWebHistory } from 'vue-router'
import config from '@/config'
import useActivityStore from '@/store/modules/activity'
import useDocumentStore from '@/store/modules/document'
import useTimelineStore from '@/store/modules/timeline'
import PathwayView from '@/views/timeline/timeline.vue'
// import useAbility from '@/services/ability.js'

/**
 * Logic for guarded routes
 * @param to
 * @param from
 * @param next
 */

// TODO: Implement this. Currently, permissions have not been fetched when this runs
// const authGuard = (to, from, next) => {
//   const ability = useAbility()

//   if (ability.can('view', 'probe')) {
//     next() // Allow navigation
//   } else {
//     next('/401')
//   }
// }

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: config.BASE_URL,
  routes: [
    {
      path: '',
      component: () => import(/* webpackChunkName: "main-layout" */ './layouts/main-layout.vue'),
      children: [
        {
          path: '',
          name: 'timeline',
          beforeRouteLeave: () => {
            const activityStore = useActivityStore()
            const documentStore = useDocumentStore()
            const timelineStore = useTimelineStore()

            timelineStore.resetModel()
            activityStore.resetModel()
            documentStore.resetModel()
            return true
          },
          component: PathwayView, //() => import(/* webpackChunkName: "timeline" */ './views/timeline/timeline.vue'),
          // beforeEnter: authGuard,
          meta: {
            title: 'LUNA Surveyor',
          },
        },
        {
          path: '/document',
          name: 'document-viewer',
          component: () =>
            import(/* webpackChunkName: "timeline" */ './views/document/document-viewer-view.vue'),
          // beforeEnter: authGuard,
          meta: {
            title: 'LUNA Surveyor - Document',
          },
        },
        {
          path: '/probe',
          name: 'luna-probe',
          component: () => import(/* webpackChunkName: "timeline" */ './views/luna-probe.vue'),
          // beforeEnter: authGuard,
          meta: {
            title: 'LUNA Surveyor - Probe',
          },
        },
      ],
    },
    {
      path: '/401',
      name: '401',
      component: () => import(/* webpackChunkName: "error-401" */ '@/views/errors/error-401.vue'),
      meta: {
        billing: true,
        title: '401 - Not Authorised',
      },
    },
  ],
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
    el.parentNode.removeChild(el),
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next()
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))

  next()
})

export default router
