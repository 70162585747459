<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <n-fixed-header>
      <n-page-header class="primary-header">
        <template #logo>
          <div class="app-logos">
            <div class="app-logo">
              <app-logo to="/" class="mr-2" />
            </div>
            <div class="app-logo header-surveyor-logo">
              <app-logo to="/" type="surveyor" />
            </div>
          </div>
        </template>
        <template #right>
          <timeline-header-filters />
        </template>
      </n-page-header>
    </n-fixed-header>
    <div class="mx-3">
      <div class="column py-0">
        <pathway-info-panel></pathway-info-panel>
      </div>
      <div class="column px-0" data-cy="vis-timeline-section">
        <vis-timeline-panel v-if="ability.can('view', 'timeline')" />
      </div>
      <div class="columns">
        <div class="column is-6">
          <n-panel :selected-tab="selectedDetailsTab" data-cy="detail-grid-section">
            <template #help>
              <div v-html="$t('activity-tooltip-help')" />
            </template>
            <template #content>
              <a-tabs
                v-model:activeKey="timelineStore.eventViewerTab"
                data-cy="details-grid-tabs"
                @change="handleTabChange"
              >
                <a-tab-pane key="activities" :tab="$t('activities')">
                  <ag-activities
                    v-if="ability.can('view', 'grid:activity')"
                    class="a-r"
                    :loading="timelineStore.timelineLoading"
                    :items="timelineMixin.filteredActivityList.value"
                  />
                  <authorisation401 v-else />
                </a-tab-pane>

                <a-tab-pane
                  v-if="ability.can('view', 'sidebar:document-master-record')"
                  key="documents"
                >
                  <!-- TODO Improve Styling -->
                  <template #tab>
                    <span>
                      {{ $t('documents') }}
                    </span>
                    <div v-if="documentStore.hasUnlinkedDocuments" class="badge unlinked-warning">
                      <i class="fas fa-circle-exclamation" />
                    </div>
                  </template>
                  <a-alert
                    v-if="documentStore.hasUnlinkedDocuments"
                    data-cy="unlinked-documents-warning"
                    type="error"
                    :banner="true"
                    :message="$t('master_document_unlinked_files_warning')"
                  />
                  <ag-documents
                    v-if="ability.can('view', 'grid:document')"
                    class="a-r"
                    :style="`height: ${documentGridHeight}`"
                    :data="timelineMixin.filteredDocumentList"
                    :loading="timelineStore.timelineLoading"
                  />
                  <authorisation401 v-else />
                </a-tab-pane>
                <a-tab-pane key="classifications" :tab="$t('classifications')">
                  <ag-classifications
                    v-if="ability.can('view', 'grid:classifications')"
                    class="a-r"
                    :loading="timelineStore.timelineLoading"
                  />
                  <authorisation401 v-else />
                </a-tab-pane>
                <a-tab-pane key="validationHistory" :tab="$t('validation-history')">
                  <ag-validation-history
                    v-if="ability.can('view', 'grid:validation-history')"
                    class="a-r"
                    :loading="timelineStore.timelineLoading"
                  />
                  <authorisation401 v-else />
                </a-tab-pane>
              </a-tabs>
            </template>
          </n-panel>
        </div>
        <div class="column is-6">
          <n-panel :selected-tab="selectedDocumentTab" data-cy="document-section">
            <template #help>
              <div v-html="$t('viewer-tooltip-help')" />
            </template>
            <template #actions>
              <!-- @adam @todo - think of what might go here, if anything -->
            </template>
            <template #content>
              <timeline-control-tabs @change="handleDocumentTabChange" />
            </template>
          </n-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import NPageHeader from '@/components/ui/n-page-header/n-page-header.vue'
import NPanel from '@/components/ui/n-panel/n-panel.vue'
import AgActivities from '@/components/timeline/ag-activities/ag-activities.vue'
import VisTimelinePanel from '@/components/timeline/vis-timeline/vis-timeline-panel.vue'
import TimelineHeaderFilters from '@/components/timeline/timeline-header-filters/timeline-header-filters.vue'
import AppLogo from '@/components/common/app-logo/app-logo.vue'
import NFixedHeader from '@/components/ui/n-fixed-header/n-fixed-header.vue'
import AgClassifications from '@/components/documents/ag-classifications/ag-classifications.vue'
import AgDocuments from '@/components/documents/ag-documents/ag-documents.vue'
import AgValidationHistory from '@/components/pathway/ag-validation-history/ag-validation-history.vue'
import Authorisation401 from '@/components/errors/authroisation-401.vue'
import TimelineControlTabs from '@/components/timeline/timeline-control-tabs/timeline-control-tabs.vue'
// import useActivityStore from '@/store/modules/activity'
import useTimelineStore from '@/store/modules/timeline'
import useDocumentStore from '@/store/modules/document'
// import bus from '@/services/global.bus.js'
import PathwayInfoPanel from '@/components/patient/pathway-info-panel.vue'
import useAbility from '@/services/ability.js'
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import useUIStore from '@/store/modules/ui'
import useTimelineMixin from '@/mixins/timeline.mixin'

const timelineMixin = useTimelineMixin()
const uiStore = useUIStore()
const timelineStore = useTimelineStore()
// const validationStore = useValidationStore()
const documentStore = useDocumentStore()

const route = useRoute()

const ability = useAbility()

timelineStore.timelineLoading = true
documentStore.setAllLoadingStates('LOADING')
timelineMixin.fetchStartupData()

// Sets the currently selected tab globally to be used in user defaults and resizing.
const selectedDetailsTab = ref(uiStore.state.eventViewerDefaultTab)
const selectedDocumentTab = ref(uiStore.state.eventInspectorDefaultTab)

const handleTabChange = ($event) => {
  uiStore.updateUserDefaults($event, 'eventViewerDefaultTab')
  selectedDetailsTab.value = $event
}

const handleDocumentTabChange = ($event) => {
  uiStore.updateUserDefaults($event, 'eventInspectorDefaultTab')
  selectedDocumentTab.value = $event
}

// TODO: refactor

// const activityStore = useActivityStore()
// bus.$on('timeline-scroll-to-activity', (activity) => {
//   this.activityStore.loadDetails({
//     ActivityID: activity.ActivityOID,
//     ActivityType: activity.ActivityType
//   })
// })

// TODO Is this needed?
// document.addEventListener('keyup', (evt) => {
//   if (evt.keyCode === 27) {
//     timelineStore.resetSearchFilters()
//   }
// })

// TODO: refactor
// unmounted() {
//   timelineStore.resetModel()
//   documentStore.resetModel()
//   activityStore.resetModel()
// },

const documentGridHeight = computed(() => {
  return documentStore.hasUnlinkedDocuments ? '64.5vh !important' : '70vh'
})

onBeforeMount(() => {
  // If there is a FileId or FileNumber in the query string, then default to the documents tab
  if (route.query.FileId || route.query.FileNumber) {
    timelineStore.eventViewerTab = 'documents'
  } else {
    timelineStore.eventViewerTab = uiStore.state.eventViewerDefaultTab
  }
})
</script>

<style lang="less" scoped>
.badge.unlinked-warning {
  right: -12px;
  bottom: 9px;
}
.primary-header {
  font-size: 12px;
  font-family: sans-serif;
  padding: 16px 15px 16px 15px !important;
  & .app-logo img {
    height: 20px;
  }
  & .ant-calendar-picker-input.ant-input {
    padding-top: 0 !important;
  }
  & .ant-btn,
  & .ant-input {
    line-height: 22px !important;
    font-size: 12px !important;
    height: 31px !important;
  }
}
.app-logos {
  display: inline-flex;
  margin-top: 8px;
}
.app-logo img {
  min-width: 185px;
  object-fit: cover;
}
.header-surveyor-logo img {
  min-width: 125px;
  margin-left: 10px;
  margin-top: 12px;
}
.ant-tabs.ant-tabs-top.ant-tabs-line {
  height: 77vh !important;
}
.wrapper-timeline .scroller {
  height: calc(100vh - 290px);
}
.a-r {
  height: 70vh !important;
}
</style>
