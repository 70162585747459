// import Vue from 'vue'
import NIcon from '@/components/ui/n-icon/n-icon.vue'
import VisTimelineItem from '@/components/timeline/vis-timeline/vis-timeline-item/vis-timeline-item.vue'
import VisTimelineGroupItem from '@/components/timeline/vis-timeline/vis-timeline-item/vis-timeline-group-item.vue'
import VisTimelineRttTooltip from '@/components/timeline/vis-timeline/vis-timeline-rtt-tooltip/vis-timeline-rtt-tooltip.vue'
// import DocumentContentTag from '@/components/document-nlp/document-content-tag/document-content-tag.vue'
import { createApp, h } from 'vue'

const renderComponentToHtml = (component, props) => {
  const app = createApp({
    render: () => h(component, { ...props }),
  })

  const mountNode = document.createElement('div')
  app.mount(mountNode)

  return mountNode
}

// export const getDocumentTag = ({ tag, text, color }) => {
//   // REMOVED: not used?
//   if (text) {
//     return renderComponentToHtml(DocumentContentTag, {
//       text,
//       tag,
//       color
//     })
//   }
//   return ''
// }

export const getNIcon = ({ type, icon, banned }) => {
  // TODO need to find a way to create a component in vue 3
  return renderComponentToHtml(NIcon, {
    icon: [type, icon],
    banned,
  })
}

export const getVisTimelineItem = (props) => {
  if (props.isGroup) {
    return renderComponentToHtml(VisTimelineGroupItem, props)
  }
  return renderComponentToHtml(VisTimelineItem, props)
}

export const getVisTimelineRttTooltip = (items, message) => {
  const filteredItems = items.filter((i) => i.value !== null && i.value !== undefined)

  const html = renderComponentToHtml(VisTimelineRttTooltip, {
    items: filteredItems,
    message,
  })

  return html
}
