import { defineStore } from 'pinia'
import _ from 'lodash'
import { configService } from '@/services/endpoints/config.js'
import ls from '@/helpers/localStorage'
import semver from 'semver'
import { ref } from 'vue'

export default defineStore('config', () => {
  const permissions = ref([])
  const appParams = ref({})

  const version = ref(ls.get('config-version') || null)

  // TODO store config in the store, not in localstorage. Why is this in localstorage?
  const getConfigByKey = (key) => {
    return _.get(ls.get('config'), key)
  }
  const fetchConfig = async () => {
    const webConfig = await configService.getWebConfig()
    const lsCompatibleVersion = webConfig['web-config'].localStorageCompatibility
    let lsCurrentVersion = ls.get('lsCompatibleVersion')

    // Set if not present in ls
    if (!lsCurrentVersion) {
      ls.set('lsCompatibleVersion', lsCompatibleVersion)
      ls.del('default-values', webConfig)
      lsCurrentVersion = lsCompatibleVersion
    }

    // reset defaults if the localstorage is incompatible
    if (semver.lt(lsCurrentVersion, lsCompatibleVersion)) {
      ls.del('default-values', webConfig)
      ls.set('lsCompatibleVersion', lsCompatibleVersion)
    }

    ls.set('config', webConfig)
  }

  const fetchPermissions = async () => {
    const response = await configService.getPermissions()
    permissions.value = response
  }

  const versionCheck = (apiVersion) => {
    // If the versions don't match request the latest version
    if (version.value !== apiVersion) {
      // Set the current requested version
      version.value = apiVersion
      ls.set('config-version', version.value)

      // Re-fetch the config
      fetchConfig()
    }
  }

  const setAppParams = (params) => {
    appParams.value = params
  }
  return {
    version,
    appParams,
    setAppParams,
    getConfigByKey,
    fetchConfig,
    versionCheck,
    fetchPermissions,
    permissions,
  }
})
