<template>
  <div id="n-fixed-header" class="n-fixed-header">
    <div :class="toggleNavClass()">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'

const active = ref(false)
const header = computed(() => {
  return document.getElementById('n-fixed-header')
})

onMounted(() => {
  window.onscroll = () => {
    active.value = window.scrollY > header.value.offsetHeight
  }
})

const toggleNavClass = () => {
  if (!active.value) {
    return 'n-fixed-header--normal'
  }
  return 'n-fixed-header--sticky'
}
</script>

<style lang="less">
.n-fixed-header {
  position: relative;
  transition: ease-in-out all 1000ms;
  &--normal {
    transition: ease-in-out all 1000ms;
  }
  &--sticky {
    display: flex;
    width: calc(100% - 80px);
    position: fixed;
    top: 42px;
    height: 62px !important;
    transition: ease-in-out all 1000ms;
    z-index: 999;
    align-content: center !important;
  }
  & .n-page-header {
    width: 100%;
  }
}
</style>
