import { defineStore } from 'pinia'
import { reactive } from 'vue'

export default defineStore('agGrid', () => {
  const agGrids = reactive({
    masterRecord: null,
    activities: null,
    documents: null,
    classifications: null,
    validations: null,
    detail: null,
    document: null,
    tags: null,
    sentences: null,
    metadata: null,
    probe: null,
  })

  return {
    agGrids,
  }
})
