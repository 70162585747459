import _ from 'lodash'
import dayjs from 'dayjs'
import config from '@/config'
import useActivityStore from '@/store/modules/activity'
import useConfigStore from '@/store/modules/config'
import useDocumentStore from '@/store/modules/document'
import useTimelineStore from '@/store/modules/timeline'
import useAgGridStore from '@/store/modules/agGrid'
import useUiStore from '@/store/modules/ui'
import useAgGridMixin from './agGridMixin'
import { computed } from 'vue'

// @todo move this to srv-config
const documentTagColorsCodes = {
  red: '#cc7066',
  blue: '#2d85a8',
  pink: '#e6b3bc',
  green: '#7f9c6c',
  purple: '#72697d',
  other: 'lightgrey',
  lime: '#92cc58',
}

// @todo move this to srv-config
const documentTagColorMap = {
  // HumanAnatomy: documentTagColorsCodes.blue,
  // BodyPart: documentTagColorsCodes.green,
  // Drug: documentTagColorsCodes.red,
  // TreatmentFunction: documentTagColorsCodes.red,
  // Specialty: documentTagColorsCodes.green,
}

export default () => {
  const activityStore = useActivityStore()
  const configStore = useConfigStore()
  const documentStore = useDocumentStore()
  const agGridStore = useAgGridStore()
  const timelineStore = useTimelineStore()
  const uiStore = useUiStore()
  const agGridMixin = useAgGridMixin()

  const timelineSortOptions = timelineStore.sortModeOptions
  const selectedDocument = timelineStore.selectedDocument
  const eventViewerTab = timelineStore.eventViewerTab

  const classifiers = configStore.getConfigByKey('web-config.documents.classifiers')

  // TODO: Reconfigure this?
  // watch (loading, () => {
  //   if (this.gridApi) {
  //     if (this.loading) {
  //       this.gridApi.showLoadingOverlay()
  //     } else {
  //       this.gridApi.hideOverlay()
  //       loadInitialDocument()
  //     }
  //   }
  // })

  // const loadInitialDocument = () => {
  //   const route = useRoute()

  //   const FileNumber = route.query.FileNumber
  //   const FileId = route.query.FileId

  //   if (FileNumber || FileId) {
  //     const selectDocument = documentStore.documents.find(
  //       (d) => d.FileNumber === FileNumber || d.FileId.toUpperCase() === FileId.toUpperCase()
  //     )
  //     selectAgRows({ data: selectDocument })
  //   }
  // }

  const getFriendlyExtension = (ext) => {
    if (!ext) {
      return 'Unknown'
    }
    return ext.replace('.', ' ').toUpperCase()
  }

  const getDocumentFileDateWithFallback = (FileDate, Activities) => {
    if (!FileDate && config.unlinkedFilesDefaultTimelineActivityDate === 'earliest') {
      return _.min(Activities.map((elm) => dayjs(elm.ActivityDate)))
    } else if (!FileDate && config.unlinkedFilesDefaultTimelineActivityDate === 'latest') {
      return _.max(Activities.map((elm) => dayjs(elm.ActivityDate)))
    }

    return FileDate
  }

  const getTagBackgroundColor = (tag) => {
    return documentTagColorMap[tag] || documentTagColorsCodes.other
  }
  const getClassifier = (Classifier) => {
    if (!Classifier) return classifiers.unknown
    return classifiers[Classifier.toLowerCase()] || classifiers.unknown
  }

  const getClassificationLabelIcon = (classification) => {
    const classifierConfig = getClassifier(classification.ClassificationStatus)

    if (classifierConfig.icon === 'alphabet') {
      if (classification[classifierConfig.iconField] === 'Referral') return 'alphabet-Ref'
      if (classification[classifierConfig.iconField] === 'MDT') return 'alphabet-MDT'

      const alphabetIcon = `alphabet-${classification[classifierConfig.iconField].substring(0, 1)}`
      return alphabetIcon
    }

    return classifierConfig.icon
  }

  const getClassificationStatusLongName = (Classifier) => {
    return getClassifier(Classifier).longName
  }

  const getClassificationStatusIcon = (activityType) => {
    // Direct match search
    const icon = getClassifier(activityType).icon

    // Search by wildcard
    if (!icon || icon === classifiers.unknown.icon) {
      const match = _.find(
        Object.values(classifiers).map((c) => c.icon),
        (el) => {
          return new RegExp(el, 'g').test(activityType)
        },
      )

      return match || classifiers.unknown.icon
    }

    return icon
  }

  const getClassificationStatusColor = (activityType) => {
    // Direct match search
    const colour = getClassifier(activityType).colour

    // Search by wildcard
    if (!colour) {
      const match = _.find(
        Object.values(classifiers).map((c) => c.colour),
        (el) => {
          return new RegExp(el, 'g').test(el)
        },
      )

      return match ? match : classifiers.unknown.colour
    }

    return colour || classifiers.unknown.colour
  }

  const getCorrectedIconBackgroundColour = (classifier) => {
    return getClassificationStatusColor(classifier) === 'white'
      ? 'black'
      : getClassificationStatusColor(classifier)
  }

  const selectRowNode = (gridApi, nodeId) => {
    // Select row in active grid
    let row = gridApi.getRowNode(nodeId)
    if (row) {
      row.setSelected(true)
    }
  }

  const selectFileRowNodes = (gridApi, FileNumber) => {
    // Selects all rows with the supplied FileNumber and gridApi
    // Used for selecting multiple classifications

    gridApi.forEachNode((row) => {
      const rowFileNumber = row.id.split('::')[1].split('|')[0]

      if (rowFileNumber === FileNumber) {
        selectRowNode(gridApi, row.id)
      }
    })
  }

  const focusRowNode = (gridApi, nodeId) => {
    gridApi.forEachNode((row) => {
      const $rowElement = document.querySelector(
        `.document-table .ag-center-cols-container [row-id="${row.id}"]`,
      )

      // Return if no row element, can be caused by grouping.
      if (!$rowElement) return

      if (row.id === nodeId) {
        $rowElement.classList.add('ag-row-focus')
        $rowElement.classList.remove('ag-row-no-focus')
      } else {
        $rowElement.classList.remove('ag-row-focus')
        $rowElement.classList.add('ag-row-no-focus')
      }
    })
  }

  const documentAgGrids = computed(() => {
    return _.pick(agGridStore.agGrids, ['classifications', 'validationHistory', 'documents'])
  })

  const selectAgRows = async (item, isProbe = false) => {
    // Selects the rows in both AG grids for classifiers and documents
    // Can be triggered from anywhere in the app

    // Return if no data, this is usually from a grouped row.
    if (!item.data) return
    const data = item.rovaGroup ? item.data[0] : item.data

    // Set the selected document panel to text (default)
    documentStore.selectedDocumentPanelView = 'text'
    // Clear the document models, to avoid header icons being set from the previous model
    documentStore.documentModels = []

    const isActivity = data.ActivityCategory ? true : false
    // Select correct document in viewer
    // Wait for new classification list to update or selection breaks
    if (data.FileNumber) {
      await selectDocumentViewer(
        {
          FileNumber: data.FileNumber,
        },
        isActivity,
        isProbe,
        item,
      )
    }

    const selectedRowIds = []

    // Get grid id from current active table or from store.
    for (const [name, agGrid] of Object.entries(documentAgGrids.value)) {
      const rowId = agGridMixin.getAgRowId(name, data)
      if (rowId) selectedRowIds.push(rowId)
      if (!agGrid) continue

      // Focus row node for documents as it has checkboxes
      if (name === 'documents') {
        focusRowNode(agGrid.api, rowId)
        continue
      }

      // Classifications to be selected, deselect all and either select one or many
      agGrid.api.deselectAll()
      // If classifications are grouped, skip this and select multiple rows in next step
      if (!item.rovaGroup && (data.ClassificationId || data.ValidationTarget === 'pathway')) {
        selectRowNode(agGrid.api, rowId)
        continue
      }

      // Select multiple rows associated with FileNumber
      selectFileRowNodes(agGrid.api, data.FileNumber)
    }

    // Triggers the selection for vis-timeline item
    setSelectedDocument(data, selectedRowIds)
  }

  const selectDocumentViewer = async (query, isActivity = false, isProbe = false, item = {}) => {
    // Set the document header switch to 'Probe' if there are compromise matches
    uiStore.state.probeHighlighting = item.data?.matches && isProbe ? 'probe' : 'rova'

    const documentModels = await documentStore.queryDocumentModels(
      query,
      isProbe
        ? [
            {
              source: 'probe',
              classifier: 'probe',
              result: 'positive',
              label: 'New Probe Compromise Classifier',
              hideInDocumentReader: false,
              name: 'probeCompromiseClassifier',
              matches: item.data.matches || [],
            },
            {
              source: 'regex',
              classifier: 'regex',
              result: 'positive',
              label: 'New Probe Regex Classifier',
              hideInDocumentReader: false,
              name: 'probeRegexClassifier',
              matches: [
                {
                  phrase: item.data.RegexPhrase,
                  sentence: item.data.RegexSentence,
                },
              ],
            },
          ]
        : [],
    )

    if (!isActivity) {
      // Return if document does not have a model associated with it
      if (documentModels.length === 0) {
        documentStore.focusedFileNumber = null
        activityStore.details = {}
        return
      }

      // Set focused values so they can be used if grids are not initialised
      documentStore.focusedFileNumber = documentModels[0].FileNumber

      // Sets values for the Inspector grid
      // Need to filter out objects or arrays as they do not display correctly
      const document = documentModels[0]
      const inspectorGridData = Object.fromEntries(
        Object.entries(document).filter(
          (values) => typeof values[1] !== 'object' && !Array.isArray(values[1]),
        ),
      )
      activityStore.details = inspectorGridData
    }
  }

  const setSelectedDocument = (data, rowIds) => {
    selectedDocument.value = data
    if (!selectedDocument.value || !timelineStore.visTimeline) return

    // Handle selection for single classification or validation
    // If timeline details are not shown, classification items are grouped,
    // so we need to select all items with the same FileNumber, as for documents.
    if (
      (selectedDocument.value.ClassificationId && uiStore.state.visShowDetail) ||
      selectedDocument.value.ValidationTarget === 'pathway'
    ) {
      return timelineStore.visTimeline.setSelection(rowIds)
    }

    // Select all items with FileNumber
    const items = timelineStore.visTimeline.getVisibleItems()
    const selectedItems = items.filter(
      (it) => it.split('::')[1]?.split('|')[0] === `${selectedDocument.value.FileNumber}`,
    )

    if (selectedItems.length === 0) return
    timelineStore.visTimeline.setSelection(selectedItems)
  }

  const setMainScrolling = (value) => {
    if (value) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    }
    if (!value) {
      document.getElementsByTagName('html')[0].style['overflow-y'] = 'scroll'
    }
  }

  return {
    configStore,
    documentStore,
    timelineSortOptions,
    selectedDocument,
    eventViewerTab,
    classifiers,
    getFriendlyExtension,
    getDocumentFileDateWithFallback,
    getTagBackgroundColor,
    getClassifier,
    getClassificationLabelIcon,
    getClassificationStatusLongName,
    getClassificationStatusIcon,
    getClassificationStatusColor,
    getCorrectedIconBackgroundColour,
    selectRowNode,
    selectFileRowNodes,
    focusRowNode,
    selectAgRows,
    selectDocumentViewer,
    documentAgGrids,
    setMainScrolling,
  }
}
