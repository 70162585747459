import { AgGridVue } from 'ag-grid-vue3'
import agGridModules from '@/helpers/ag-grid-modules'
import useAgGridStore from '@/store/modules/agGrid'

export default () => {
  const agGridStore = useAgGridStore()

  const rowIds = {
    classifications: (data) => {
      if (data.FileNumber && data.ClassificationId) {
        return `classification::${data.FileNumber}|${data.ClassificationId}`
      }

      return `classification::${data.FileNumber}`
    },
    validationHistory: (data) => {
      if (data.FileNumber && data.ClassificationId) {
        return `validation::${data.FileNumber}|${data.ClassificationId}`
      }
      if (!data.ValidationID) return null

      return `validation::${data.ValidationID}`
    },
    documents: (data) => {
      if (!data.FileNumber) return null
      return `document::${data.FileNumber}`
    },
  }

  const agModules = agGridModules

  const getAgRowId = (gridName, data) => {
    return rowIds[gridName](data)
  }

  const onGridReady = (agGrid, gridName) => {
    agGridStore.agGrids[gridName] = agGrid
    agGrid.api.sizeColumnsToFit()
    return agGrid
  }

  return {
    agModules,
    AgGridVue,
    getAgRowId,
    onGridReady,
  }
}
