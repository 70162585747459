import { api } from '@/services/http.js'

export default {
  getPID(PTLUniqueID) {
    if (!PTLUniqueID) return null
    return api.get('/patient/pid', PTLUniqueID)
  },
  getPUIDS(PTLUniqueID) {
    // PTL
    return api.get('/patient/puids', PTLUniqueID)
  },
  getPathwayInfo(PTLUniqueID) {
    return api.get('/patient/pathway-info', PTLUniqueID)
  },
  getCensusDate() {
    return api.get('/patient/census-date')
  },
}
