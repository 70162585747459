<template>
  <ag-grid-vue
    data-cy="sentences-grid"
    style="width: 100%; height: 70vh"
    :modules="agGridMixin.agModules"
    class="ag-theme-balham"
    :default-col-def="defaultColDef"
    :column-defs="columnDefs"
    :row-data="rowData"
    :animate-rows="true"
    :master-detail="true"
    :row-group-panel-show="'always'"
    :row-height="32"
    :enable-cell-text-selection="false"
    :enable-browser-tooltips="true"
    :suppress-copy-rows-to-clipboard="true"
    :suppress-row-click-selection="true"
    :pivot-panel-show="'always'"
    :enable-range-selection="true"
    :enable-charts="true"
    @grid-ready="(params) => agGridMixin.onGridReady(params, 'sentences')"
  />
</template>

<script setup>
import _ from 'lodash'
import nlp from 'compromise'
import { AgGridVue } from 'ag-grid-vue3'
import defaultColDef from '@/mixins/globalDefaultColDef'
import agClassificationListDefs from '@/config/ag-grid-defs/ag-classifications.js'
import { computed } from 'vue'
import useDocumentStore from '@/store/modules/document'
import useAgGridMixin from '@/mixins/agGridMixin.js'

const agGridMixin = useAgGridMixin()
const documentStore = useDocumentStore()

const columns = [
  {
    headerName: 'FileId',
    field: 'FileId',
    filter: 'agTextColumnFilter',
    hide: true,
  },
  {
    headerName: 'Document',
    field: 'documentNumber',
    width: 80,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Position',
    field: 'position',
    width: 80,
    suppressSizeToFit: true,
  },
  {
    headerName: 'Sentence',
    field: 'sentence',
    resizable: true,
    filter: 'agTextColumnFilter',
    flex: true,
  },
]

const columnDefs = computed(() => {
  return columns || agClassificationListDefs.columnDefs
})

const rowData = computed(() => {
  const arr = []

  _.forEach(documentStore.documentModels, (item, fileNumber) => {
    let sentences = nlp(item.TextContent).sentences().json()

    _.forEach(sentences, (sentence, index) => {
      arr.push({
        fileId: item.uuid,
        position: index + 1,
        documentNumber: fileNumber + 1,
        sentence: sentence.text,
      })
    })
  })
  return arr
})
</script>
