import {
  getFriendlyDate,
  getFriendlyDateTime,
  getDate,
  getDateComparator,
  getDateFromSSQL,
} from '@/mixins/n-time.js'

export default {
  suppressColumnVirtualisation: true,
  sidebar: {
    toolPanels: ['columns', 'filters'],
    position: 'right',
  },
  rowDrag: true,
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      key: 'totalAndFilter',
      align: 'left',
    },
    { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
    { statusPanel: 'agAggregationComponent', align: 'right' },
  ],
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
  defaultToolPanel: '',
  columnDefs: [
    {
      headerName: 'Activity Unique ID',
      field: 'ActivityUniqueID',
      filter: 'agTextColumnFilter',
      hide: true,
    },
    {
      headerName: '',
      field: 'ActivityIcon',
      filter: false,
      tooltipField: 'ActivityType',
      resizable: false,
      width: 40,
      maxWidth: 40,
    },
    {
      headerName: 'Activity Type',
      field: 'ActivityType',
      filter: 'agSetColumnFilter',
      hide: true,
    },
    {
      headerName: '',
      field: 'ActivityStatusIcon',
      filter: false,
      tooltipField: 'ActivityStatus',
      resizable: false,
      width: 35,
      maxWidth: 35,
    },
    {
      headerName: 'Classifications',
      // TODO Key should be classifications, need to change in data
      field: '_documents',
      width: 80,
      suppressSizeToFit: true,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Date Label',
      field: 'ActivityDateLabel',
      filter: 'agSetColumnFilter',
      hide: false, // hidden because it's used as a tooltip on Activity Date, but can be unhidden by user
      width: 160,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Date',
      field: 'ActivityDate',
      filter: 'agDateColumnFilter',
      width: 60,
      suppressSizeToFit: true,
      sortingOrder: ['desc', 'asc'],
      sort: 'desc',
      tooltipField: 'ActivityDateLabel',
      valueGetter: (data) => {
        if (data.data && data.data.ActivityDate) {
          return getDateFromSSQL(data.data.ActivityDate)
        }
      },
      cellRenderer: (params) => {
        if (params.data && params.data.ActivityDate) {
          return getFriendlyDate(params.data.ActivityDate)
        } else if (params.value) {
          return getFriendlyDate(params.value)
        }
      },
    },
    {
      headerName: 'Pathway ID',
      field: 'PathwayID',
      tooltipField: 'PathwayID',
      hide: true,
    },
    {
      headerName: 'Status',
      field: 'ActivitySubType',
      filter: 'agSetColumnFilter',
      width: 140,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Activity ID Type',
      field: 'ActivityIDType',
      filter: 'agSetColumnFilter',
      hide: true,
    },
    {
      headerName: 'Activity ID',
      field: 'ActivityID',
      tooltipField: 'ActivityIDType',
      hide: true,
    },
    {
      headerName: 'Specialty',
      field: 'Specialty',
      filter: 'agSetColumnFilter',
      hide: true,
    },
    {
      headerName: 'TF',
      field: 'TreatmentFunction',
      filter: 'agSetColumnFilter',
      suppressSizeToFit: true,
    },
    {
      headerName: 'Consultant',
      field: 'Consultant',
      filter: 'agSetColumnFilter',
      tooltipField: 'Consultant',
      width: 80,
    },
    {
      headerName: 'Last Modified By',
      field: 'SystemUsernameFull',
      filter: 'agSetColumnFilter',
      hide: true,
    },
    {
      headerName: 'Last Modified On',
      field: 'ModifiedTime',
      filter: 'agDateColumnFilter',
      width: 140,
      filterParams: {
        comparator: getDateComparator,
      },
      tooltipField: 'SystemUsernameFull',
      valueGetter: (data) => {
        if (data.data && data.data.ModifiedTime) {
          return getDate(data.data.ModifiedTime)
        }
      },
      cellRenderer: (params) => {
        if (params.data && params.data.ModifiedTime) {
          return getFriendlyDateTime(params.data.ModifiedTime)
        } else if (params.value) {
          return getFriendlyDate(params.value)
        }
      },
    },
  ],
}
