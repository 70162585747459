<template>
  <div class="timelineHeaderFilters">
    <div class="columns pr-3 is-justify-content-right">
      <div class="column is-narrow switch">
        <tooltip-switch />
      </div>
      <div class="column is-narrow switch">
        <tag-switch />
      </div>
      <div class="column is-narrow" v-if="ability.can('view', 'filters')">
        <n-tooltip :text="$t('Filters')" placement="bottom">
          <a-button
            data-cy="filters-btn"
            class="filters-button"
            :type="hasFilters ? 'primary' : 'default'"
            @click="showFilterDD = !showFilterDD"
          >
            <i class="fal fa-filter" />
            <template v-if="hasFilters"> ON </template>
            <template v-else> OFF </template>
          </a-button>
        </n-tooltip>
      </div>
      <div class="column is-narrow" data-cy="timeline-date-range-filter">
        <a-range-picker
          v-model:value="timelineStore.dateRangeFilter"
          class="n-col n-m-0 range-picker"
          :format="config.LOCALE.DEFAULT_DATE_FORMAT"
        />
      </div>
      <div class="column is-narrow">
        <a-input-search
          v-model:value="timelineStore.activitySearchInput"
          data-cy="search"
          addon="search"
          :placeholder="$t('search-activities')"
          class="certain-category-search n-m-0"
        />
      </div>
      <div class="column is-narrow">
        <patient-dropdown-button />
      </div>
    </div>

    <div v-if="showFilterDD" class="filters-dd n-p-2">
      <filters />
    </div>
  </div>
</template>

<script setup>
import config from '@/config'
// import _ from 'lodash'
import PatientDropdownButton from '@/components/demo/patient-dropdown-button.vue'
import Filters from '@/components/filters/filters.vue'
import NTooltip from '@/components/ui/n-tooltips/n-tooltip.vue'
import TooltipSwitch from '../../ui/n-tooltips/tooltip-switch.vue'
import TagSwitch from '../../ui/n-tag/tag-switch.vue'
import useFilterStore from '@/store/modules/filter'
import useTimelineStore from '@/store/modules/timeline'
import { ref, computed } from 'vue'
import useAbility from '@/services/ability'

const ability = useAbility()
const timelineStore = useTimelineStore()
const filterStore = useFilterStore()

defineProps({
  hideRefresh: {
    type: Boolean,
    default: false,
  },
  hidePatientDropdown: {
    type: Boolean,
    default: false,
  },
  justify: {
    type: String,
    default: '',
  },
})

const showFilterDD = ref(false)

const hasFilters = computed(() => {
  return filterStore.getFilters().length > 0
})
</script>

<style lang="less">
.timelineHeaderFilters {
  .filters-dd {
    position: absolute;
    left: 0;
    width: 100%;
    background: white;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid #eee;
  }
}
</style>
<style scoped>
.switch {
  padding-top: 18px;
}

@media screen and (max-width: 1280px) {
  .range-picker {
    width: 200px !important;
  }
  .certain-category-search {
    width: 110px !important;
  }
  .filters-button {
    width: 50px !important;
    padding-left: 4px !important;
  }
  .timelineHeaderFilters .column {
    padding: 12px 6px;
    &.switch {
      padding-top: 18px;
    }
  }
}
</style>
