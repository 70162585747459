import config from '@/config'
import dayjs from 'dayjs'

export const daysBetweenDates = (start, end) => {
  return dayjs(end).diff(dayjs(start), 'days')
}

export const getDateFromSSQL = (date) => {
  return dayjs(date, config.LOCALE.SQL_DATE_FORMAT).toDate()
}

export const getDateTimeFromSSQL = (date) => {
  return dayjs(date).toDate()
}

export const getDate = (date, justDate) => {
  let dt = dayjs(date)

  if (justDate) {
    dt = dayjs(dt.format('DD/MM/YYYY'), 'DD/MM/YYYY')
  }

  if (dt.isValid()) {
    return dt.toDate()
  }

  return date
}

export const getFriendlyUNIXTime = (date) => {
  let dt = dayjs(date)

  if (dt.isValid()) {
    return dt.format(config.LOCALE.DEFAULT_DATE_FORMAT)
  }
  return date
}

export const getFriendlyDate = (date) => {
  if (!date) {
    return null
  }
  let dt = dayjs(date, config.LOCALE.SQL_DATE_FORMAT)

  if (dt.isValid()) {
    return dt.format(config.LOCALE.DEFAULT_DATE_FORMAT)
  }
  return date
}

export const getFriendlyDateTime = (date) => {
  let dt = dayjs(date, config.LOCALE.SQL_DATE_TIME_FORMAT)

  if (dt.isValid()) {
    return dt.format(config.LOCALE.DEFAULT_DATE_TIME_FORMAT)
  }
  return date
}

export const getDateComparator = (a, b) => {
  let aDate = getDate(a, true)

  let bDate = getDate(b, true)

  if (aDate > bDate) {
    return -1
  } else if (aDate < bDate) {
    return 1
  }
  return 0
}
