import useConfigStore from '@/store/modules/config'

export default () => {
  const configStore = useConfigStore()

  const activity = configStore.getConfigByKey('web-config.activities')

  const {
    statusIcon,
    activityStatusColors,
    activityIcon,
    activityIconColors,
    statusIconLongNames,
    activityLongNames,
    activityMediumNames,
    activityShortNames,
  } = activity

  const rttNationalDescriptions = configStore.getConfigByKey(
    'web-config.rtt.rttNationalDescription',
  )

  // TODO: extract config getter functions into generic function below

  // const getActivityKey = (activityType, key) => {
  //   const activityProperty = ls.get('config').webConfig.activities[key]

  //   return activityType && activityProperty[activityType.toLowerCase()]
  //     ? activityProperty[activityType.toLowerCase()]
  //     : activityProperty.unknown || ''
  // }
  const getActivityIcon = (activityType) => {
    return activityType && activityIcon[activityType.toLowerCase()]
      ? activityIcon[activityType.toLowerCase()]
      : activityIcon.unknown
  }

  const getStatusIcon = (status) => {
    return status && statusIcon[status.toLowerCase()]
      ? statusIcon[status.toLowerCase()]
      : statusIcon['n/a']
  }

  const getRTTNationalDescriptions = (code) => {
    const filtered = rttNationalDescriptions.filter((item) => {
      return item.id === parseInt(code)
    })

    if (filtered.length > 0) {
      return filtered[0]
    }
    return {}
  }

  const getActivityIllogicalHighlightClass = ({ illogical, illogicalSeverity }) => {
    let className = ''

    if (illogical) {
      switch (illogicalSeverity) {
        case 1:
          className += 'hl-red'
          break
        case 2:
          className += 'hl-orange'
          break
        case 3:
          className += 'hl-orange-l-20'
          break
        case 4:
          className += 'hl-orange-l-40'
          break
        case 5:
          className += 'hl-orange-l-60'
          break
        default:
          break
      }
    }

    return className
  }

  const getActivityColor = (activityType) => {
    return activityType && activityIconColors[activityType.toLowerCase()]
      ? activityIconColors[activityType.toLowerCase()]
      : ''
  }

  const getActivityShortName = (activityType) => {
    return activityType && activityShortNames[activityType.toLowerCase()]
      ? activityShortNames[activityType.toLowerCase()]
      : activityShortNames.unknown
  }

  const getActivityMediumName = (activityType) => {
    return activityType && activityMediumNames[activityType.toLowerCase()]
      ? activityMediumNames[activityType.toLowerCase()]
      : activityMediumNames.unknown
  }

  const getActivityLongName = (activityType) => {
    return activityType && activityLongNames[activityType.toLowerCase()]
      ? activityLongNames[activityType.toLowerCase()]
      : activityLongNames.unknown
  }

  const getActivityIconLongName = (activityType) => {
    return activityType && statusIconLongNames[activityType.toLowerCase()]
      ? statusIconLongNames[activityType.toLowerCase()]
      : statusIconLongNames.unknown
  }

  const getStatusColor = (status) => {
    return status && activityStatusColors[status.toLowerCase()]
      ? activityStatusColors[status.toLowerCase()]
      : ''
  }

  return {
    getActivityIcon,
    getStatusIcon,
    getRTTNationalDescriptions,
    getActivityIllogicalHighlightClass,
    getActivityColor,
    getActivityShortName,
    getActivityMediumName,
    getActivityLongName,
    getActivityIconLongName,
    getStatusColor,
    statusIcon,
    activityIcon,
  }
}
