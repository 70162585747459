import log from '@/logger'
import useUIStore from '@/store/modules/ui'

export default (request) => {
  const { method, url, data } = request

  log.info('API Request', method, url, data)
  const uiStore = useUIStore()
  uiStore.loaderStart()

  return request
}
