<template>
  <div>
    <table data-cy="timeline-tooltip" class="vis-tt">
      <tr v-if="message">
        <th><i class="fas fa-circle-exclamation" /></th>
        <td class="warning" v-html="message" />
      </tr>

      <tr v-for="(item, index) of items" :key="index" data-cy="timeline-tooltip-row">
        <th>
          <span
            v-if="item.colour"
            class="tooltip-colour-square"
            :class="`has-background-${item.colour} ${item.colour === 'white' ? 'has-border' : ''}`"
          ></span>
          {{ item.title }}
        </th>
        <td :class="item.class" v-html="getParsedDataValue(item.type, item.value)" />
      </tr>
    </table>
  </div>
</template>

<script setup>
import { getFriendlyDate, getFriendlyDateTime } from '@/mixins/n-time'

defineProps({
  items: {
    type: Array,
    required: true,
  },
  message: {
    type: String,
    required: false,
    default: null,
  },
})

const getParsedDataValue = (type, value) => {
  if (type === Date) {
    return getFriendlyDate(value)
  }
  if (type === 'DateTime') {
    return getFriendlyDateTime(value)
  }
  return value
}
</script>

<style scoped>
.vis-tt {
  font-size: 11px;
}
.tooltip-colour-square {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 2px;

  &.has-border {
    border: 0.3px solid black;
  }
}
</style>
