import { matchArray } from 'searchjs'
import { mapValues, groupBy, omit, forEach } from 'lodash'

const operators = {
  and: 'AND',
  $and: 'AND',
  $or: 'OR',
  or: 'OR',
  equals: '$eq', // @todo im not sure if this even used in the frontend as its identical to the "exactly" comparison
  exactly: '$eq',
  more_than: '$gt',
  less_than: '$lt',
  in: '$in',
  nin: '$nin',
  before: '$lt',
  after: '$lt',
  on: '$eq',
}

export const queryByParams = (data, params) => {
  // {_join: "OR", terms: [{name:"John", age:30},{age:35}]}
  return matchArray(data, params)
}

export const getOperator = (op) => {
  return operators[op]
}

export const groupByMetaType = (conditions) => {
  return mapValues(groupBy(conditions, 'metaType'), (clist) =>
    clist.map((car) => omit(car, 'metaType')),
  )
}

export const generateSearchQuery = (conditions) => {
  if (conditions && conditions.conditions && conditions.conditions.length > 0) {
    const conditionalGroups = groupByMetaType(conditions.conditions[0].predicates)

    const query = {}

    // Set the initial segment join type
    query._join = getOperator(conditions.type)

    // Query each conditional group
    for (let metaType in conditionalGroups) {
      const predicatesGroups = conditionalGroups[metaType]

      let groupQuery = query[metaType] || {
        terms: [],
        _join: getOperator(conditions.type),
      }

      // Set the group join type if its not already set
      if (!groupQuery._join) {
        groupQuery._join = getOperator(conditions.type)
      }

      const formQueryParamFromPredicate = (type, comparison, attr, value) => {
        const predicateQuery = {}
        const comparisonOperator = getOperator(comparison)

        if (comparisonOperator === '$in') {
          // Simple Array query
          predicateQuery[attr] = value
        } else if (comparisonOperator === '$nin') {
          // Simple Array query
          predicateQuery[attr] = value
          predicateQuery._not = true
        }

        return predicateQuery
      }

      forEach(predicatesGroups, (predicate) => {
        // Form the correct predicate query
        const predicateQuery = formQueryParamFromPredicate(
          predicate.type,
          predicate.comparison,
          predicate.attr,
          predicate.value,
        )

        // Merge the predicate query with the core query group
        groupQuery.terms.push(predicateQuery)
      })

      // Update the main query object with the final group query result
      query[metaType] = groupQuery
    }

    return query
  }
}
