import { api } from '@/services/http'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep, size } from 'lodash'
import { captureException } from '@sentry/vue'

export const documentService = {
  get(params) {
    return api.get('/document/get', params)
  },
  getLinkStatus(payload) {
    return api.post('/document/linked-status', payload)
  },
  getList(params) {
    return api.post('/document/get-list', params)
  },
  queryDocumentModels(params, additionalClassifications) {
    return api.post('/document/get-document-models', params).then((resp) => {
      let data = cloneDeep(resp)

      // This is a hack to add additional classifications to the document, only used for Probe. Need to move to probe route
      if (additionalClassifications && data[0]?.classifications) {
        data[0].classifications.push(...additionalClassifications)
      }

      const getIndicesOf = (searchStr, str, caseSensitive) => {
        let searchStrLen = searchStr?.length ?? 0

        if (searchStrLen === 0) {
          return []
        }

        let startIndex = 0,
          index,
          indices = []

        if (!caseSensitive) {
          /* eslint-disable no-param-reassign */
          str = str.toLowerCase()
          searchStr = searchStr.toLowerCase()
          /* eslint-enable no-param-reassign */
        }

        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
          indices.push(index)
          startIndex = index + searchStrLen
        }
        return indices
      }

      data.forEach((item) => {
        if (size(item.classifications) > 0) {
          // Loop through each classification
          item.classifications.forEach((classification) => {
            const parsedMatches = []

            // Loop through each match
            classification.matches.forEach((match) => {
              let sentence = match.sentence

              // #Hacky fix for rova not removing header from list
              if (item.lists) {
                item.lists.forEach((list) => {
                  if (sentence.startsWith(list.heading)) {
                    sentence = sentence.replace(list.heading, '').trim()
                  }
                })
              }

              // Determine the offsets (sometimes there can be multiple)
              let offsets = getIndicesOf(sentence, item.TextContent)

              if (offsets.length === 0) {
                captureException(
                  new Error('Rova sentence not found in document, attempting with phrase.'),
                )
                offsets = getIndicesOf(match.phrase, item.TextContent)
                sentence = match.phrase
              }

              if (offsets.length !== match.sentenceCountInDoc) {
                captureException(
                  new Error(
                    `Found ${offsets.length} occurances of matched sentence but Rova found ${match.sentenceCountInDoc} matches`,
                  ),
                )
                match.sentenceIndexInDoc = 1
              }

              parsedMatches.push({
                id: uuidv4(),
                offset: offsets[match.sentenceIndexInDoc - 1],
                length: sentence.length,
                ...match,
                sentence, // This will override the match sentence so it uses it in the document reader
              })
            })

            // Add the new parsed matches to the object
            classification.parsedMatches = parsedMatches
          })
        }
      })

      return data
    })
  },
}
