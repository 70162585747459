import { computed } from 'vue'
import useFilterStore from '@/store/modules/filter.js'
import useDocumentStore from '@/store/modules/document.js'
import { generateSearchQuery, queryByParams } from '@/helpers/searchjs-filters.js'

export default () => {
  const filterStore = useFilterStore()
  const documentStore = useDocumentStore()

  const filteredClassificationList = computed(() => {
    // TODO: THis is not triggring the computed property
    const queryParams = generateSearchQuery(filterStore.segment)

    if (!queryParams || !queryParams.classification) {
      return documentStore.classificationList
    }

    const result = queryByParams(documentStore.classificationList, queryParams.classification)

    return result
  })

  return {
    filteredClassificationList,
  }
}
