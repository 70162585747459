import log from '@/logger'
import useConfigStore from '@/store/modules/config.js'
import useUIStore from '@/store/modules/ui'

export default (response) => {
  const { status, config, data, headers } = response
  const configStore = useConfigStore()
  const uiStore = useUIStore()

  // Check if the config versions match up, excluding config routes
  if (headers['x-config-version'] && !response.config.url.includes('config')) {
    configStore.versionCheck(headers['x-config-version'])
  }

  uiStore.loaderFinish()

  log.info('API', status, config.method, config.url, response)
  return data
}
