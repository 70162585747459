import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

// @see https://www.ag-grid.com/javascript-grid-set-license/
// @todo should just cherry-pick the modules we need instead of loading all-modules - lots of resource overhead?
// eslint-disable-next-line no-unused-vars
import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-062287}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{MBI_HEALTHCARE_TECHNOLOGIES_LIMITED}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Luna}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Luna}_need_to_be_licensed___{Luna}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{26_August_2025}____[v3]_[01]_MTc1NjE2MjgwMDAwMA==dc7d2776101485542026c18f0203569f',
)
