import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import config from '@/config'
import router from '../router'
import { createApp } from 'vue'
const app = createApp()

export default () => {
  if (config.sentry.enable && config.sentry.dsn) {
    Sentry.init({
      environment: config.sentry.env,
      dsn: config.sentry.dsn,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', /^\//, config.sentry.tracingOrigin],
        }),
        new Sentry.Integrations.Vue({
          app,
          attachProps: true,
        }),
      ],

      // To set a uniform sample rate
      tracesSampleRate: config.sentry.tracesSampleRate,

      release: `${config.RELEASE_NAME}@${config.PACKAGE_VERSION}`,
    })
  }
}
