<template>
  <div id="vis-slider-wrapper" class="n-m-0">
    <div id="slider-wrapper">
      <input id="vis-timeline-slider" type="text" />
    </div>
  </div>
</template>

<script setup>
import IonRangeSlider from './range-slider'
import { defineProps, onMounted, onUnmounted, watch } from 'vue'
import useTimelineStore from '@/store/modules/timeline'

const timelineStore = useTimelineStore()

const emit = defineEmits(['start', 'update', 'finish', 'change'])
let histSlider = null

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
  clip: {
    type: Boolean,
    default: true,
  },
  block: {
    type: Boolean,
    default: false,
  },
  grid: {
    type: Boolean,
    default: true,
  },
  gridNum: {
    type: Number,
    default: 4,
  },
  step: {
    type: Number,
    default: 1,
  },
  hideMinMax: {
    type: Boolean,
    default: true,
  },
  hideFromTo: {
    type: Boolean,
    default: false,
  },
  toFixed: {
    type: Boolean,
    default: false,
  },
  fromFixed: {
    type: Boolean,
    default: false,
  },
  forceEdges: {
    type: Boolean,
    default: false,
  },
  dragInterval: {
    type: Boolean,
    default: false,
  },
  keyboard: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'double',
    validator: function (value) {
      return ['double', 'single'].indexOf(value) !== -1
    },
  },
  width: {
    type: Number,
    default: 650,
  },
  barGap: {
    type: Number,
    default: 5,
  },
  barRadius: {
    type: Number,
    default: 4,
  },
  prettify: Function,
  transitionDuration: {
    type: Number,
    default: 100,
  },
})

watch(
  () => props.options,
  (value) => {
    update(value)
  },
  { deep: true },
)

onMounted(() => {
  histSlider = new IonRangeSlider('#vis-timeline-slider', {
    skin: 'round',
    min: props.options.min,
    max: props.options.max,
    from: props.options.from,
    to: props.options.to,
    type: props.type,
    grid: props.grid,
    step: props.step,
    from_fixed: props.fromFixed,
    to_fixed: props.toFixed,
    hide_min_max: props.hideMinMax,
    hide_from_to: props.hideFromTo,
    force_edges: props.forceEdges,
    drag_interval: props.dragInterval,
    // grid_num: this.Number,
    block: props.block,
    keyboard: props.keyboard,
    prettify: props.prettify,
    onStart: (val) => {
      emit('start', val)
    },
    onUpdate: (val) => {
      emit('update', val)
    },
    onFinish: (val) => {
      emit('finish', val)
    },
    onChange: (val) => {
      emit('change', val)
      timelineStore.dateSliderChanged = true
    },
  })
})

onUnmounted(() => {
  histSlider.destroy()
})

const update = (value) => {
  histSlider.update(value)
}
</script>

<style lang="less">
#slider-wrapper {
  width: 100%;
}
#vos-slider-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.vis-slider-bar {
  pointer-events: none;
}
.irs {
  font-size: 12px;
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
  cursor: pointer;
}
.irs-bar {
  cursor: pointer;
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}
.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}
.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}
.irs-handle.type_last {
  z-index: 2;
}
.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default;
}
.irs-min {
  left: 0;
}
.irs-max {
  right: 0;
}
.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
  z-index: 99;
}
.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}
.irs-with-grid .irs-grid {
  display: block;
}
.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}
.irs-grid-pol.small {
  height: 6px;
}
.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000;
}
.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}
.lt-ie9 .irs-disable-mask {
  background: #000;
  filter: alpha(opacity=0);
  cursor: not-allowed;
}
.irs-disabled {
  opacity: 0.4;
}
.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}
.irs--round {
  height: 50px;
}
.irs--round.irs-with-grid {
  height: 65px;
  margin: 1rem 2.1%;
}
.irs--round .irs-line {
  top: 36px;
  height: 6px;
  background-color: #dee4ec;
  border-radius: 6px;
}
.irs--round .irs-bar {
  top: 36px;
  height: 6px;
  background-color: #01274c;
}
.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px;
}
.irs--round .irs-shadow {
  height: 6px;
  bottom: 21px;
  background-color: rgba(222, 228, 236, 0.5);
}
.irs--round .irs-handle {
  cursor: pointer;
  top: calc(50% - 26px / 2 + 5px);
  width: 26px;
  height: 26px;
  background-color: #ffffff;
  z-index: 9;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
}
.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #f0f6ff;
}
.irs--round .irs-min,
.irs--round .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: #01274c;
  color: white;
  border-radius: 4px;
}
.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
  position: absolute;
  display: block;
  content: '';
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #01274c;
}
.irs--round .irs-grid {
  height: 25px;
}
.irs--round .irs-grid-pol {
  background-color: #dedede;
}
.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px;
}
</style>
