export default {
  visTimelineGroupOptions: [
    { value: 'none', label: 'No Grouping' },
    { value: 'ActivityCategory', label: 'Category' },
    { value: 'ActivityStatus', label: 'Status' },
    { value: 'TreatmentFunction', label: 'Treatment Function' },
    { value: 'Division', label: 'Division' },
    { value: 'Site', label: 'Site' },
    { value: 'Specialty', label: 'Specialty' },
    { value: 'Consultant', label: 'Consultant' },
    { value: 'PathwayID', label: 'Pathway ID' },
    { value: 'ActivitySubType', label: 'Activity Subtype' },
  ],
  visTimelineOrderOptions: [
    { value: 'none', label: 'Chronological' },
    { value: 'asc', label: 'Alphabetical (A - Z)' },
    { value: 'desc', label: 'Alphabetical (Z - A)' },
  ],
  visTimelineOptions: [
    { label: 'Colours', value: 'visShowColour' },
    { label: 'Activity', value: 'visShowActivities' },
    { label: 'Rova™', value: 'visShowRova' },
    { label: 'Validation', value: 'visShowValidations' },
    { label: 'RTT', value: 'visShowRTT' },
    { label: 'Local/National', value: 'visShowLocalRTTCodes' },
    { label: 'Detail', value: 'visShowDetail' },
  ],
  visTimelineMaxZoom: 9000 * 1.5 * 60 * 60 * 24 * 365, // 18 months,
  visMinHeight: '270px',
  visMaxHeight: '520px',
}
