<template>
  <a-tooltip
    class="tooltip"
    :placement="placement"
    :title="text"
    :overlay-class-name="overlayClassName()"
  >
    <slot />
  </a-tooltip>
</template>

<script setup>
import useUIStore from '@/store/modules/ui'

// name: 'NTooltip',
defineProps({
  text: {
    type: String,
    required: true,
  },
  placement: {
    type: String,
    required: true,
  },
})
const uiStore = useUIStore()

const overlayClassName = () => {
  return uiStore.state.tooltipsEnabled ? '' : 'tt-hidden'
}
</script>

<style>
.tt-hidden {
  display: none;
}
</style>
