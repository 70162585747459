import { defineStore } from 'pinia'
import { activityService } from '@/services/endpoints/activity'
import { ref } from 'vue'

const selection = ref(null)
const details = ref({})

export default defineStore('activity', () => {
  const loadDetails = async (query) => {
    const data = await activityService.getDetails(query)

    if (!data) return

    details.value = data
  }
  // TODO: Refactor?
  // const resetModel = () =>{
  //   this.$patch(defaultState())
  //   // Object.assign(this, defaultState())
  // }
  return {
    selection,
    details,
    loadDetails,
  }
})
