import { api } from '@/services/http'

export const configService = {
  getWebConfig() {
    return api.get('/config')
  },
  getLocale(code) {
    return api.get(`/config/locale/${code}`)
  },
  getPermissions() {
    return api.get('/config/permissions')
  },
}
