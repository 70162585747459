import { api } from '@/services/http'

export const validationService = {
  getValidationHistory(params) {
    return api.post('/validations/history', params)
  },
  reject(params) {
    return api.post('/validations/reject', params)
  },
  change(params) {
    return api.post('/validations/change', params)
  },
  approve(params) {
    return api.post('/validations/approve', params)
  },
  add(params) {
    return api.post('/validations/add', params)
  },
  classificationHistory(params) {
    return api.post('/validations/classification/history', params)
  },
}
