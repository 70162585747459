<template>
  <div>
    <n-tooltip
      :text="
        copyTagLabel ? 'Clicking tags copies Label and Value' : 'Clicking tags copies Value Only'
      "
      placement="top"
    >
      <div id="wrapper">
        <a-switch
          class="n-mb-0 n-mr-1 tc-switch"
          data-cy="tag-copy-switch"
          v-model:checked="copyTagLabel"
          default-checked
          @change="uiStore.updateUserDefaults(copyTagLabel, 'copyTagLabel')"
        />
        <i
          class="n-m-0 fal fa-md"
          :class="copyTagLabel ? 'info fas fa-tags' : 'info off fas fa-tag'"
        />
      </div>
    </n-tooltip>
  </div>
</template>
<script setup>
import useUIStore from '@/store/modules/ui'
import NTooltip from '@/components/ui/n-tooltips/n-tooltip.vue'
import { ref } from 'vue'

const uiStore = useUIStore()

const copyTagLabel = ref(uiStore.state.copyTagLabel)
</script>
<style scoped lang="less">
.tc-switch {
  z-index: 98;
  pointer-events: auto;
}
#wrapper {
  position: relative;
}
.n-m-0.info {
  position: absolute;
  top: 6px !important;
  left: 27px !important;
  transition: left 0.2s;
  transition-timing-function: cubic-bezier(0.39, -0.01, 0.53, 0.78);
  z-index: 99;
  pointer-events: none;
  &.off {
    top: 5.5px !important;
    left: 6px !important;
  }
}
</style>
