<template>
  <img
    :src="logoPath"
    alt="Luna Surveyor"
    :class="{
      'has-pointer': to !== '',
      'is-icon': icon,
      'enterprise-logo': type !== 'surveyor' && !icon,
    }"
    @click="handleLogoClick"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

// name: 'AppLogo',
const props = defineProps({
  icon: {
    type: Boolean,
    required: false,
    default: false,
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
  to: {
    type: String,
    required: false,
    default: '',
  },
})
const router = useRouter()

const logoPath = computed(() => {
  if (props.icon) {
    // eslint-disable-next-line global-require
    return new URL('@/assets/images/mbi-logo-badge-only.png', import.meta.url).href
  }
  if (props.type === 'surveyor') {
    // eslint-disable-next-line global-require
    return new URL('@/assets/images/sureveyor.png', import.meta.url).href
  }
  // eslint-disable-next-line global-require
  return new URL('@/assets/images/luna-enterprise.png', import.meta.url).href
})
const handleLogoClick = () => {
  if (props.to) {
    router.push(props.to)
  }
}
</script>

<style scoped>
.is-icon {
  max-width: 50%;
  display: block;
  width: 50%;
  left: 25%;
  top: 25% !important;
}

.has-pointer {
  cursor: pointer;
}

.enterprise-logo {
  height: 40px !important;
}
</style>
